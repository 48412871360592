import CreatorStudio from './pages/creator-studio/CreatorStudio';
import NewVideo from './pages/creator-studio/outlets/new-video/NewVideo';
import Home from './pages/home/Home';
import ProtectedRoute from './pages/routes/ProtectedRoute';
import StudentDashboard from './pages/student-dashboard/StudentDashboard';
import AddVideo from './pages/student-dashboard/outlets/add-video/AddVideo';
import StudentQuiz from './pages/student-quiz/StudentQuiz';
import StudentVideo from './pages/student-video/StudentVideo';
import TeacherVideo from './pages/teacher-video/TeacherVideo';
import Edit from './pages/teacher-video/outlets/Edit/Edit';
import GenerateQuiz from './pages/teacher-video/outlets/GenerateQuiz/GenerateQuiz';
import ThoughtQuestions from './pages/teacher-video/outlets/ThoughtQuestions/ThoughtQuestions';
import Thoughts from './pages/teacher-video/outlets/Thoughts/Thoughts';
import Topics from './pages/teacher-video/outlets/Topics/Topics';
import Transcript from './pages/teacher-video/outlets/Transcript/Transcript';
import GlobalStyle from './theme/global-style';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import CreatorVideoList from './pages/creator-studio/outlets/video-list/VideoList';
import StudentVideoList from './pages/student-dashboard/outlets/video-list/VideoList';
import {useContext, useEffect} from 'react';
import {ThemeContext} from './theme/ThemeContext';
import {ThemeProvider} from 'styled-components';
import {Toaster} from 'react-hot-toast';
import TopicQuestions from './pages/teacher-video/outlets/TopicQuestions/TopicQuestions';
import PreTest from './pages/student-video/outlets/pre-test/PreTest';
import VideoNoPersonalization from './pages/student-video/outlets/video-no-personalization/VideoNoPersonalization';
import PostTest from './pages/student-video/outlets/post-test/PostTest';

function App() {
  const {theme, loadTheme} = useContext(ThemeContext);

  useEffect(() => {
    loadTheme();
  }, [loadTheme]);

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Routes>
          <Route path='' element={<Home />} />
          {/* Student */}
          <Route path='student' element={<ProtectedRoute />}>
            <Route path='' element={<StudentDashboard />}>
              <Route path='' element={<StudentVideoList />} />
              <Route path='new' element={<AddVideo />} />
            </Route>
            <Route path='quiz/:videoId' element={<StudentQuiz />} />
            <Route path='watch/:videoId' element={<StudentVideo />}>
              <Route path='' element={<VideoNoPersonalization />} />
              <Route path='pre-test' element={<PreTest />} />
              <Route path='post-test' element={<PostTest />} />
            </Route>
            <Route path='watch' element={<Navigate replace to='/student' />} />
          </Route>
          {/* Creator */}
          <Route path='creator' element={<ProtectedRoute />}>
            <Route path='' element={<CreatorStudio />}>
              <Route path='' element={<CreatorVideoList />} />
              <Route path='new' element={<NewVideo />} />
            </Route>
            <Route path='my-video/:videoId' element={<TeacherVideo />}>
              <Route path='' element={<Edit />} />
              <Route path='transcript' element={<Transcript />} />
              <Route path='transcript-questions' element={<GenerateQuiz />} />
              <Route path='topics' element={<Topics />} />
              <Route path='topic-questions' element={<TopicQuestions />} />
              <Route path='concepts' element={<Thoughts />} />
              <Route path='concept-questions' element={<ThoughtQuestions />} />
            </Route>
          </Route>
        </Routes>
      </ThemeProvider>
      <Toaster />
    </BrowserRouter>
  );
}

export default App;
