import React from 'react';
import {Pagination as BPagination} from 'react-bootstrap';

interface PaginationProps {
  currentPage: number;
  itemCount: number;
  pageCount: number;
  onChangePage: (pageNumber: number) => void;
  label: string;
  limit: number;
}

const Pagination = ({currentPage, itemCount, pageCount, onChangePage, label, limit}: PaginationProps) => {
  return (
    <div className='d-flex flex-column gap-1'>
      {/* [start index]-[end index] of [count] feedback  */}
      <div className='d-flex flex-row justify-content-center'>
        <span className='fs-6 fst-italic'>
          {itemCount === 0 ? 0 : currentPage * limit - limit + 1}-{currentPage * limit > itemCount ? itemCount : currentPage * limit} of {itemCount} {label}
        </span>
      </div>
      <div className='d-flex flex-row justify-content-center'>
        <BPagination>
          {currentPage === 1 ? <BPagination.First disabled /> : <BPagination.First onClick={() => onChangePage(1)} />}
          {currentPage > 1 && <BPagination.Item onClick={() => onChangePage(currentPage - 1)}>{currentPage - 1}</BPagination.Item>}
          <BPagination.Item active>{currentPage}</BPagination.Item>
          {currentPage < pageCount && <BPagination.Item onClick={() => onChangePage(currentPage + 1)}>{currentPage + 1}</BPagination.Item>}
          {currentPage < pageCount ? <BPagination.Last onClick={() => onChangePage(pageCount)} /> : <BPagination.Last disabled />}
        </BPagination>
      </div>
    </div>
  );
};

export default Pagination;
