import {useState} from 'react';
import useFetch from './hooks/useFetch';
import {getMessageFromError} from '../../../../utils/errors';
import toast from 'react-hot-toast';
import {Alert, Button, Col, Container, Row, Table} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faNewspaper, faRobot, faTrash} from '@fortawesome/free-solid-svg-icons';
import Loading from '../../../../components/loading/Loading';
import {toHHMMSS} from '../../../../utils/time';
import TextModal from './components/TextModal';
import {useDeleteAllThoughtsForVideoMutation, useGenerateThoughtsMutation} from '../../../../store/thoughts/thoughts.api';
import {useVideo} from '../../TeacherVideo';
import {faYoutube} from '@fortawesome/free-brands-svg-icons';
import {Thought} from '../../../../types/dtos';
import VideoModal from '../../../../components/video-modal/VideoModal';

const Thoughts = () => {
  const {video} = useVideo();

  const {thoughts, isError, isFetching, isSuccess, errorMessage} = useFetch(video.id);
  const canGenerateThoughts = thoughts.length === 0;
  const canDeleteThoughts = thoughts.length > 0;
  const [showTextModal, setShowTextModal] = useState<boolean>(false);
  const [showVideoModal, setShowVideoModal] = useState<boolean>(false);
  const [selectedThought, setSelectedThought] = useState<Thought | undefined>();

  const [generateThoughts, {isLoading: isLoading1}] = useGenerateThoughtsMutation();
  const [deleteThoughts, {isLoading: isLoading2}] = useDeleteAllThoughtsForVideoMutation();
  const isLoading = isLoading1 || isLoading2 || isFetching;

  const onClickGenerateThoughts = () => {
    if (canGenerateThoughts) {
      toast.promise(generateThoughts(video.id).unwrap(), {
        loading: 'Generating concepts...',
        success: 'Concepts generated successfully',
        error: (err) => `Failed to generate concepts. ${getMessageFromError(err)}`,
      });
    }
  };

  const onClickDeleteThoughts = () => {
    if (canDeleteThoughts) {
      toast.promise(deleteThoughts(video.id).unwrap(), {
        loading: 'Deleting concepts...',
        success: 'Concepts deleted successfully',
        error: (err) => `Failed to delete concepts. ${getMessageFromError(err)}`,
      });
    }
  };

  const onClickShowText = (thought: Thought) => {
    setSelectedThought(thought);
    setShowTextModal(true);
  };

  const onClickShowVideo = (thought: Thought) => {
    setSelectedThought(thought);
    setShowVideoModal(true);
  };

  return (
    <Container className='py-4'>
      <Row>
        <Col>
          <h2>Actions</h2>
          <div className='d-flex flex-row gap-2 justify-content-center align-items-center'>
            <Button variant='success' disabled={!canGenerateThoughts} onClick={onClickGenerateThoughts}>
              Generate concepts
              <FontAwesomeIcon icon={faRobot} className='ms-1' />
            </Button>
            <Button variant='danger' disabled={!canDeleteThoughts} onClick={onClickDeleteThoughts}>
              Delete all concepts
              <FontAwesomeIcon icon={faTrash} className='ms-1' />
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className='mb-4'>Concepts</h2>
          {isLoading ? (
            <Loading page={false} />
          ) : isError ? (
            <Alert variant='danger'>{errorMessage}</Alert>
          ) : !thoughts.length ? (
            <p className='text-muted fst-italic'>No thoughts found</p>
          ) : (
            isSuccess && (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Elaborated thought</th>
                    <th>Category</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {thoughts.map((thought, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{toHHMMSS(thought.startSecond)}</td>
                      <td>{toHHMMSS(thought.endSecond)}</td>
                      <td>{thought.elaboratedThought}</td>
                      <td>{thought.category || 'None'}</td>
                      <td>
                        <div className='d-flex flex-row gap-2'>
                          <Button variant='primary' onClick={() => onClickShowText(thought)}>
                            <FontAwesomeIcon icon={faNewspaper} />
                          </Button>
                          <Button variant='danger' onClick={() => onClickShowVideo(thought)}>
                            <FontAwesomeIcon icon={faYoutube} />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )
          )}
        </Col>
      </Row>
      {selectedThought && showTextModal && <TextModal text={selectedThought.transcriptText} show={showTextModal} onHide={() => setShowTextModal(false)} />}
      {selectedThought && showVideoModal && <VideoModal show={showVideoModal} onHide={() => setShowVideoModal(false)} start={selectedThought.startSecond} end={selectedThought.endSecond} videoId={video.idYoutube} />}
    </Container>
  );
};

export default Thoughts;
