import {apiSlice} from '../api/api.slice';
import {GetStudentViewsInfoForVideoResponse} from './views.api-types';

const viewsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStudentViewsInfoForVideo: builder.query<GetStudentViewsInfoForVideoResponse, string>({
      query: (videoId) => `views/student-info/${videoId}`,
    }),
  }),
});

export const {useGetStudentViewsInfoForVideoQuery} = viewsApiSlice;
