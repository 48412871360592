import {useCallback, useEffect, useState} from 'react';
import {OrderingValue} from '../types/ordering';

const useOrdering = (values: OrderingValue[]) => {
  const [orderValue, setOrderValue] = useState(values[0].name);
  const [orderKey, setOrderKey] = useState(values[0].key);
  const orderBy = values.find((item) => item.key === orderKey)?.orderBy || '';
  const orderDirection = values.find((item) => item.key === orderKey)?.orderDirection || 'asc';

  const changeOrderHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    const selected = values.find((item) => item.key === value);
    if (!selected) return;
    setOrderValue(value);
    setOrderKey(selected.key);
  };

  const reset = useCallback(() => {
    setOrderValue(values[0].name);
    setOrderKey(values[0].key);
  }, [values]);

  useEffect(() => {
    reset();
  }, [reset]);

  return {
    orderValue,
    orderKey,
    orderBy,
    orderDirection,
    changeOrderHandler,
    reset,
  };
};

export default useOrdering;
