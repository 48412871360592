import {Dispatch, SetStateAction, useState} from 'react';
import * as Styles from './styles';
import {Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';

interface SearchBarProps {
  placeholder: string;
  resetPage?: () => void;
  setSearchQuery: Dispatch<SetStateAction<string>>;
}

const SearchBar = ({placeholder, resetPage, setSearchQuery}: SearchBarProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const MAX_LENGTH = 100;

  const onSubmit: React.FormEventHandler = (e) => {
    e.preventDefault();
    if (searchValue.trim() === '') setSearchQuery('');
    setSearchQuery(searchValue);
    resetPage && resetPage();
  };

  return (
    <div className='d-flex flex-column flex-grow-1 gap-1'>
      <label>Search</label>
      <Styles.SearchBar onSubmit={onSubmit}>
        <input type='text' className='search-input' placeholder={placeholder} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} maxLength={MAX_LENGTH} />
        <Button type='submit' className='search-icon'>
          <FontAwesomeIcon icon={faSearch} />
        </Button>
      </Styles.SearchBar>
    </div>
  );
};

export default SearchBar;
