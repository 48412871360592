import styled from 'styled-components';

export const Question = styled.div`
  font-size: 1.2rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  margin-bottom: 2rem;
`;

export const Answer = styled.button`
  font-size: 1.2rem;
  font-weight: 500;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid ${({theme}) => theme.primary};
  width: 100%;
  background-color: ${({theme}) => theme.textPrimary};

  &:hover:enabled {
    cursor: pointer;
    background-color: ${({theme}) => theme.primary};
    color: ${({theme}) => theme.textPrimary};
  }

  &.selected-wrong {
    background-color: rgb(255, 40, 0);
    color: ${({theme}) => theme.textPrimary};
  }

  &.selected-correct {
    background-color: rgb(65, 169, 76);
    color: ${({theme}) => theme.textPrimary};
  }
`;
