import useFetch from './hooks/useFetch';
import LoadingError from '../../../../components/loading-error/LoadingError';
import Panel from '../../../../components/panel/Panel';
import * as Styles from './styles';
import {Video} from '../../../../types/dtos';

interface ViewsInfoProps {
  video: Video;
}

const ViewsInfo = ({video}: ViewsInfoProps) => {
  const {info, isLoading, isSuccess, isError, errorMessage} = useFetch(video.id);

  if (isLoading || isError) return <LoadingError isLoading={isLoading} isError={isError} errorMessage={errorMessage} />;
  if (isSuccess && info)
    return (
      <Panel>
        <h2 className='fs-5'>Views Info</h2>
        <p className='text-muted'>You have watched the following pieces of video:</p>
        <Styles.TimeBar>
          {info.intervals.map((interval, index) => (
            <Styles.TimeBarInterval key={index} left={(interval.start / video.duration) * 100} width={((interval.end - interval.start) / video.duration) * 100} />
          ))}
        </Styles.TimeBar>
        {/* <p>Views: {info.viewCount}</p>
        <p>Watch time: {info.watchTime}</p>
        <p>Watched: {info.watched}</p> */}
      </Panel>
    );

  return null;
};

export default ViewsInfo;
