import {useEffect, useMemo, useState} from 'react';
import {Container, Row, Button, Col, Alert} from 'react-bootstrap';
import useOrdering from '../../../../hooks/useOrdering';
import SearchBar from '../../../../components/search-bar/SearchBar';
import Ordering from '../../../../components/ordering/Ordering';
import Pagination from '../../../../components/pagination/Pagination';
import usePagination from '../../../../hooks/usePagination';
import VideoCard from './components/video-card/VideoCard';
import {useGetStudentVideosQuery} from '../../../../store/videos/videos.api';
import Loading from '../../../../components/loading/Loading';
import {Video} from '../../../../types/dtos';
import {OrderingValue} from '../../../../types/ordering';

const VideoList = () => {
  const orderingList: OrderingValue[] = useMemo(
    () => [
      {name: 'Video name (A-Z)', key: 'nameASC', orderBy: 'name', orderDirection: 'asc'},
      {name: 'Video name (Z-A)', key: 'nameDESC', orderBy: 'name', orderDirection: 'desc'},
    ],
    []
  );

  const LIMIT = 100;
  const [videos, setVideos] = useState<Video[]>([]);
  const [total, setTotal] = useState<number>(0);
  const {orderValue, changeOrderHandler, orderBy, orderDirection, reset: resetOrder} = useOrdering(orderingList);
  const {currentPage, pageCount, onChangePage, offset} = usePagination({limit: LIMIT, itemCount: total});
  const [searchQuery, setSearchQuery] = useState('');

  const {data, isFetching, isError, isSuccess} = useGetStudentVideosQuery({
    skip: offset,
    take: LIMIT,
    orderBy,
    orderDirection,
    search: searchQuery.trim().length > 0 ? searchQuery : undefined,
  });

  const reset = () => {
    resetOrder();
    setSearchQuery('');
    onChangePage(1);
  };

  useEffect(() => {
    if (isSuccess) {
      setVideos(data.videos);
      setTotal(data.total);
    }
  }, [isSuccess, data]);

  if (isFetching) return <Loading page={true} />;
  return (
    <Container className='py-5'>
      <Row className='mb-4'>
        <div className='d-flex flex-column flex-md-row gap-2 gap-md-3 w-100'>
          {/* Ordering select */}
          <Ordering changeOrderHandler={changeOrderHandler} orderValue={orderValue} orderingList={orderingList} />
          {/* Search box */}
          <div className='d-flex flex-row align-items-end gap-2'>
            <SearchBar placeholder={'Search for a video'} resetPage={() => onChangePage(1)} setSearchQuery={setSearchQuery} />
            {/* Reset filter and order */}
            <Button variant='info' onClick={reset}>
              Reset
            </Button>
          </div>
        </div>
      </Row>
      {isError && <Alert variant='danger'>An error occurred while fetching videos</Alert>}
      {isSuccess && videos.length === 0 && <Alert variant='info'>No videos found</Alert>}
      {isSuccess && videos.length > 0 && (
        <Row className='mb-3'>
          {videos.map((video) => (
            <Col key={video.id} lg={3} md={4} sm={6} xs={12}>
              <VideoCard video={video} />
            </Col>
          ))}
        </Row>
      )}
      <Row>
        <Pagination currentPage={currentPage} pageCount={pageCount} itemCount={total} label={'videos'} limit={LIMIT} onChangePage={onChangePage} />
      </Row>
    </Container>
  );
};

export default VideoList;
