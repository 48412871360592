import {toast} from 'react-hot-toast';

const DURATION = parseInt(process.env.REACT_APP_TOAST_DURATION as string) || 3000;

export function toastSuccess(message: string) {
  toast.success(message, {
    position: 'top-right',
    duration: DURATION,
  });
}

export function toastSuccessBottom(message: string) {
  toast.success(message, {
    position: 'bottom-right',
    duration: DURATION,
  });
}

export function toastError(message: string) {
  toast.error(message, {
    position: 'top-right',
    duration: DURATION,
  });
}
