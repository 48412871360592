import Styles from './styles';

const Footer = () => {
  //Get current year
  const year = new Date().getFullYear();

  return (
    <Styles.Footer>
      <span>SmartQuiz &copy; {year}</span>
    </Styles.Footer>
  );
};

export default Footer;
