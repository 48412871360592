import React from 'react';
import {matchPath, useLocation, useNavigate} from 'react-router-dom';

interface ILink {
  name: string;
  path: string;
  pattern: string;
}

const useMenu = ({linkList}: {linkList: ILink[]}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [active, setActive] = React.useState(0);

  //Memoized links
  const links = React.useMemo(() => linkList, [linkList]);

  //Handle menu click event (change active menu item and redirect)
  const handleMenuClick = (id: number) => {
    if (id < links.length) {
      navigate(links[id].path);
    }
  };

  React.useEffect(() => {
    const pathname = location.pathname;
    links.forEach((link, index) => {
      const match = matchPath(
        {
          path: link.pattern,
          end: true,
        },
        pathname
      );
      if (!!match) {
        setActive(index);
      }
    });
  }, [active, links, location.pathname]);

  return {
    active,
    links,
    handleMenuClick,
  };
};

export default useMenu;
