import styled from 'styled-components';

export const StudentVideoPlayer = styled.div`
  height: 100%;
`;

export const PlayerOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 50;
`;
