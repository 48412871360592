import Loading from '../loading/Loading';
import {Alert, Container} from 'react-bootstrap';

interface LoadingErrorProps {
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string | null;
}

const LoadingError = ({isLoading = false, isError = false, errorMessage = null}: LoadingErrorProps) => {
  if (isLoading)
    return (
      <Container className='py-4'>
        <Loading page={false} />
      </Container>
    );
  if (isError)
    return (
      <Container className='py-4'>
        <Alert variant='danger'>{errorMessage}</Alert>
      </Container>
    );
  return null;
};

export default LoadingError;
