import styled from 'styled-components';

export const Header = styled.div`
  position: relative;
  min-height: 14rem;
  background-color: rgba(${(props) => props.theme.secondaryRGB}, 0.8);
  color: ${(props) => props.theme.textPrimary};
  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;
