import styled from 'styled-components';

export const TimeSliderContainer = styled.div`
  .time-track {
    height: 0.6rem;
  }

  .time-track-0 {
    background-color: ${(props) => props.theme.secondary};
  }

  .time-track-1 {
    background-color: #dddddd;
  }

  .time-thumb-active {
    height: 1rem;
    width: 1rem;
    transform: translate(-0.5rem, -0.3rem);
    border-radius: 50%;
    background-color: ${(props) => props.theme.secondary};
  }

  .time-mark {
    height: 1.2rem;
    width: 0.4rem;
    background-color: purple;
    transform: translate(-0.3rem, -0.3rem);
  }
`;
