import React from 'react';
import {Accordion, Button} from 'react-bootstrap';
import IntervalSlider from '../../../../../../components/interval-slider/IntervalSlider';
import {ThoughtQuestion, Video} from '../../../../../../types/dtos';
import Answer from '../../../../../../components/answer/Answer';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faKey} from '@fortawesome/free-solid-svg-icons';
import {faYoutube} from '@fortawesome/free-brands-svg-icons';
import VideoModal from '../../../../../../components/video-modal/VideoModal';

interface Props {
  question: ThoughtQuestion;
  duration: number;
  index: number;
  video: Video;
}

const QuestionAccordionItem = ({question, duration, index, video}: Props) => {
  const [showVideo, setShowVideo] = React.useState<boolean>(false);

  return (
    <Accordion.Item eventKey={question.id}>
      <Accordion.Header>
        <span>{`${index}. ${question.text}`}</span>
        {question.thought.category === 'Essential' && (
          <span>
            <FontAwesomeIcon icon={faKey} className='text-warning ms-2' />
          </span>
        )}
      </Accordion.Header>
      <Accordion.Body>
        <div className='d-flex flex-row justify-content-between align-items-center gap-3'>
          <Button variant='danger' onClick={() => setShowVideo(true)}>
            <FontAwesomeIcon icon={faYoutube} />
          </Button>
          <div className='flex-grow-1'>
            <IntervalSlider from={question.thought.startSecond} to={question.thought.endSecond} max={duration} />
          </div>
        </div>
        <div className='mb-3' />
        {question.answers.map((answer) => (
          <Answer key={answer.id} text={answer.text} feedback={answer.feedback} isCorrect={answer.isCorrect} />
        ))}
      </Accordion.Body>
      {showVideo && <VideoModal show={showVideo} onHide={() => setShowVideo(false)} start={question.thought.startSecond} end={question.thought.endSecond} videoId={video.idYoutube} />}
    </Accordion.Item>
  );
};

export default QuestionAccordionItem;
