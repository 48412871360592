import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import QuestionItem from './components/question/Question';
import screenfull from 'screenfull';
import {selectActiveQuestions} from '../../../../store/player/player.slice';

interface Props {
  toggleFullScreen: () => void;
}

const QuizModal = ({toggleFullScreen}: Props) => {
  const questions = useSelector(selectActiveQuestions);
  const [show, setShow] = useState(false);
  const [wasFullScreen, setWasFullScreen] = useState(false);

  useEffect(() => {
    if (questions.length > 0) {
      //Show the modal
      setShow(true);
      //If the user was in fullscreen, exit fullscreen
      if (screenfull.isEnabled && screenfull.isFullscreen) {
        setWasFullScreen(true);
        toggleFullScreen();
      }
    } else {
      setShow(false);
      if (wasFullScreen) toggleFullScreen();
    }
  }, [questions.length, toggleFullScreen, wasFullScreen]);

  useEffect(() => {
    return () => {
      setWasFullScreen(false);
    };
  }, []);

  if (questions.length === 0) return null;
  return (
    <Modal show={show} backdrop='static' keyboard={false} centered>
      <Modal.Body>
        <QuestionItem question={questions[0]} />
      </Modal.Body>
    </Modal>
  );
};

export default QuizModal;
