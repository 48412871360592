import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {Socket} from 'socket.io-client';
import {RootState} from '..';

interface IInitialState {
  socket: Socket | null;
  isEstablishingConnection: boolean;
  isConnectionFailed: boolean;
  isConnected: boolean;
  isReconnecting: boolean;
  isReconnectionFailed: boolean;
}

const initialState: IInitialState = {
  //The socket instace
  socket: null,
  isEstablishingConnection: false,
  isConnectionFailed: false,
  //if the connection is established, then isEstablishingConnection and isConnectionFailed are false
  isConnected: false,
  isReconnecting: false,
  isReconnectionFailed: false,
  //if the reconnection is established, then isReconnecting and isReconnectionFailed are false
};

const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    //Start the socket connection, the middleware will handle the rest
    startConnecting: (state, payload: PayloadAction<{videoId: string; accessToken: string}>) => {
      state.isEstablishingConnection = true;
      //Reset the connection status
      state.isConnectionFailed = false;
      state.isReconnecting = false;
      state.isReconnectionFailed = false;
    },
    //Connection successful established, save the data
    connectionEstablished: (state, action) => {
      const socket = action.payload.socket;
      state.socket = socket;
      //Update the connection status to success parameters
      state.isEstablishingConnection = false;
      state.isConnectionFailed = false;
      state.isConnected = true;
    },
    //Connection failed, update the state
    connectionFailed: (state) => {
      state.isEstablishingConnection = false;
      state.isConnectionFailed = true;
      state.isConnected = false;
    },
    //Start the reconnection process, the middleware will handle the rest
    startReconnecting: (state) => {
      state.isReconnecting = true;
      //Reset the reconnection status
      state.isReconnectionFailed = false;
      state.isConnected = false;
    },
    //Reconnection successful established, save the data
    reconnectionEstablished: (state) => {
      state.isReconnecting = false;
      state.isReconnectionFailed = false;
      state.isConnected = true;
    },
    //Reconnection failed, update the state
    reconnectionFailed: (state) => {
      state.isReconnecting = false;
      state.isReconnectionFailed = true;
      state.isConnected = false;
    },
    //Disconnect socket in case user manually disconnects
    disconnectSocket: (state) => {
      //Reset the state
      return initialState;
    },
  },
});

export const {startConnecting, connectionEstablished, connectionFailed, startReconnecting, reconnectionEstablished, reconnectionFailed, disconnectSocket} = socketSlice.actions;

export default socketSlice.reducer;

//Selectors
//Return the connection boolean trackers
export const selectIsEstablishingConnection = (state: RootState) => state.socket.isEstablishingConnection;
export const selectIsConnectionFailed = (state: RootState) => state.socket.isConnectionFailed;
export const selectIsConnected = (state: RootState) => state.socket.isConnected;
export const selectIsReconnecting = (state: RootState) => state.socket.isReconnecting;
export const selectIsReconnectionFailed = (state: RootState) => state.socket.isReconnectionFailed;
export const selectSocket = (state: RootState) => state.socket.socket;
