import {Accordion, Alert, Button, Col, Container, Row} from 'react-bootstrap';
import useFetch from './hooks/useFetch';
import {useDeleteAllQuestionsForVideoMutation, useGenerateQuestionsMutation} from '../../../../store/questions/questions.api';
import {getMessageFromError} from '../../../../utils/errors';
import Loading from '../../../../components/loading/Loading';
import QuestionAccordionItem from './components/question-accordion-item/QuestionAccordionItem';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRobot, faTrash} from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';
import {useVideo} from '../../TeacherVideo';

const GenerateQuiz = () => {
  const {video} = useVideo();

  const {questions, isSuccess, isFetching: isLoading1, isError, errorMessage} = useFetch(video.id);
  const [generateQuestions, {isLoading: isLoading2}] = useGenerateQuestionsMutation();
  const [deleteQuestions, {isLoading: isLoading3}] = useDeleteAllQuestionsForVideoMutation();
  const isLoading = isLoading1 || isLoading2 || isLoading3;

  const canGenerateQuestions = questions.length === 0 && !isLoading;
  const canDeleteQuestions = questions.length > 0 && !isLoading;

  const onClickGenerate = () => {
    //Generate questions for the video
    if (canGenerateQuestions)
      toast.promise(generateQuestions(video.id).unwrap(), {
        loading: 'Generating questions...',
        success: 'Questions generated successfully',
        error: (err) => `Failed to generate questions. ${getMessageFromError(err)}`,
      });
  };

  const onClickDeleteAll = () => {
    //Delete all questions for the video
    if (canDeleteQuestions)
      toast.promise(deleteQuestions(video.id).unwrap(), {
        loading: 'Deleting questions...',
        success: 'Questions deleted successfully',
        error: (err) => `Failed to delete questions. ${getMessageFromError(err)}`,
      });
  };

  if (isSuccess)
    return (
      <Container className='py-4'>
        <Row>
          <Col>
            <h2>Actions</h2>
            <div className='d-flex flex-row gap-2 justify-content-center align-items-center mt-3'>
              <Button variant='success' onClick={onClickGenerate} disabled={!canGenerateQuestions}>
                Generate questions from transcript with AI
                <FontAwesomeIcon icon={faRobot} className='ms-1' />
              </Button>
              <Button variant='danger' onClick={onClickDeleteAll} disabled={!canDeleteQuestions}>
                Delete all questions
                <FontAwesomeIcon icon={faTrash} className='ms-1' />
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className='mt-4'>Question list</h2>
            <p>Click on the question to see the answers.</p>
            {isLoading ? (
              <Loading page={false} />
            ) : isError ? (
              <Alert variant='danger'>{errorMessage}</Alert>
            ) : !questions.length ? (
              <p className='text-muted fst-italic'>No questions found</p>
            ) : (
              <Accordion defaultActiveKey={questions.map((q) => q.id)}>
                {questions.map((question) => (
                  <QuestionAccordionItem key={question.id} question={question} duration={video.duration} />
                ))}
              </Accordion>
            )}
          </Col>
        </Row>
      </Container>
    );

  return null;
};

export default GenerateQuiz;
