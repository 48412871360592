import React from 'react';
import * as Styles from './styles';

interface VideoContainerProps {
  children: React.ReactNode;
  containerClassName?: string;
}

const VideoContainer = ({children, containerClassName}: VideoContainerProps) => {
  return <Styles.VideoContainer className={containerClassName}>{children}</Styles.VideoContainer>;
};

export default VideoContainer;
