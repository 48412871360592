import {useGetStudentViewsInfoForVideoQuery} from '../../../../../store/views/views.api';
import useError from '../../../../../hooks/useError';

const useFetch = (videoId: string) => {
  const {data: info, isLoading, isError, error, isSuccess} = useGetStudentViewsInfoForVideoQuery(videoId);

  const errorMessage = useError({isError, error});

  return {info, isLoading, isError, isSuccess, errorMessage};
};

export default useFetch;
