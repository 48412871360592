import {useEffect, useState} from 'react';
import {useGetVideoQuestionsQuery} from '../../../../../store/questions/questions.api';
import useError from '../../../../../hooks/useError';
import {InVideoQuestion} from '../../../../../types/dtos';

const useFetch = (videoId: string) => {
  const {data, isFetching, isError, error, isSuccess} = useGetVideoQuestionsQuery(videoId);

  const [questions, setQuestions] = useState<InVideoQuestion[]>([]);

  useEffect(() => {
    if (isSuccess) setQuestions(data);
  }, [data, isSuccess]);

  const errorMessage = useError({isError, error});

  return {
    questions,
    isSuccess,
    isFetching,
    isError,
    errorMessage,
  };
};

export default useFetch;
