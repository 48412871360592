import React from 'react';
import PageSkeleton from '../../components/page-skeleton/PageSkeleton';
import PageHeader from '../../components/page-header/PageHeader';
import useMenu from '../../hooks/useMenu';
import {Outlet} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {selectUser} from '../../store/auth/auth.slice';
import {User} from '../../types/dtos';

const CreatorStudio = () => {
  const linkList = [
    {
      name: 'My videos',
      path: '/creator',
      pattern: '/creator',
    },
    {
      name: 'Upload video',
      path: '/creator/new',
      pattern: '/creator/new',
    },
  ];

  const {active, handleMenuClick} = useMenu({linkList});
  const user = useSelector(selectUser) as User;

  return (
    <PageSkeleton>
      <PageHeader>
        <PageHeader.Title>Creator Studio</PageHeader.Title>
        <PageHeader.Subtitle>
          Welcome back, <span className='fw-bolder'>{user.username}</span>! Here you can manage your videos.
        </PageHeader.Subtitle>
        <PageHeader.Menu>
          {linkList.map((link, index) => (
            <PageHeader.MenuItem key={index} className={active === index ? 'active' : ''} onClick={() => handleMenuClick(index)}>
              {link.name}
            </PageHeader.MenuItem>
          ))}
        </PageHeader.Menu>
      </PageHeader>
      <Outlet />
    </PageSkeleton>
  );
};

export default CreatorStudio;
