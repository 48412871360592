import {Accordion} from 'react-bootstrap';
import * as Styles from './styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import IntervalSlider from '../../../../../../components/interval-slider/IntervalSlider';
import {InVideoQuestion} from '../../../../../../types/dtos';

interface QuestionAccordionItemProps {
  question: InVideoQuestion;
  duration: number;
}

const QuestionAccordionItem = ({question, duration}: QuestionAccordionItemProps) => {
  return (
    <Accordion.Item eventKey={question.id}>
      <Accordion.Header>{question.text}</Accordion.Header>
      <Accordion.Body>
        <IntervalSlider from={question.taggedSecond} to={question.popupSecond} max={duration} />
        <div className='mb-3' />
        {question.answers?.map((answer) => (
          <Styles.Answer key={answer.id}>
            <div className='half'>
              <FontAwesomeIcon className={`fs-3 ${answer.isCorrect ? 'text-success' : 'text-danger'}`} icon={answer.isCorrect ? faCheckCircle : faTimesCircle} />
              <span className='ms-2'>{answer.text}</span>
            </div>
          </Styles.Answer>
        ))}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default QuestionAccordionItem;
