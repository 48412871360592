import {useEffect, useState} from 'react';
import {useGetThoughtQuestionsQuery} from '../../../../../store/questions/questions.api';
import useError from '../../../../../hooks/useError';
import {ThoughtQuestion} from '../../../../../types/dtos';

const useFetch = (videoId: string) => {
  const {data, isFetching, isError, error, isSuccess} = useGetThoughtQuestionsQuery(videoId);

  const [questions, setQuestions] = useState<ThoughtQuestion[]>([]);

  useEffect(() => {
    if (isSuccess) setQuestions(data);
  }, [data, isSuccess]);

  const errorMessage = useError({isError, error});

  return {
    questions,
    isSuccess,
    isFetching,
    isError,
    errorMessage,
  };
};

export default useFetch;
