import {Accordion, Alert, Button, Col, Container, Form, Row} from 'react-bootstrap';
import useFetch from './hooks/useFetch';
import {useDeleteAllThoughtQuestionsForVideoMutation, useGenerateQuestionsFromThoughtsMutation} from '../../../../store/questions/questions.api';
import {getMessageFromError} from '../../../../utils/errors';
import Loading from '../../../../components/loading/Loading';
import QuestionAccordionItem from './components/question-accordion-item/QuestionAccordionItem';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRobot, faTrash} from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';
import {useVideo} from '../../TeacherVideo';
import {useMemo, useState} from 'react';

const ThoughtQuestions = () => {
  const {video} = useVideo();

  const {questions, isSuccess, isFetching, isError, errorMessage} = useFetch(video.id);

  const [generateQuestionsFromThoughts, {isLoading: isLoading1}] = useGenerateQuestionsFromThoughtsMutation();
  const [deleteQuestions, {isLoading: isLoading2}] = useDeleteAllThoughtQuestionsForVideoMutation();
  const [showOnlyKeyQuestions, setShowOnlyKeyQuestions] = useState<boolean>(false);
  const isLoading = isLoading1 || isLoading2 || isFetching;

  const questionsToShow = useMemo(() => {
    return showOnlyKeyQuestions ? questions.filter((q) => q.thought.category === 'Essential') : questions;
  }, [questions, showOnlyKeyQuestions]);

  const canGenerateQuestions = questions.length === 0 && !isLoading;
  const canDeleteQuestions = questions.length > 0 && !isLoading;

  const onClickGenerateFromThoughts = () => {
    //Generate questions for the video from topics
    if (canGenerateQuestions)
      toast.promise(generateQuestionsFromThoughts(video.id).unwrap(), {
        loading: 'Generating questions...',
        success: 'Questions generated successfully',
        error: (err) => `Failed to generate questions. ${getMessageFromError(err)}`,
      });
  };

  const onClickDeleteAll = () => {
    //Delete all questions for the video
    if (canDeleteQuestions)
      toast.promise(deleteQuestions(video.id).unwrap(), {
        loading: 'Deleting questions...',
        success: 'Questions deleted successfully',
        error: (err) => `Failed to delete questions. ${getMessageFromError(err)}`,
      });
  };

  if (isSuccess)
    return (
      <Container className='py-4'>
        <Row>
          <Col>
            <h2>Actions</h2>
            <div className='d-flex flex-row gap-2 justify-content-center align-items-center mt-3'>
              <Button variant='success' onClick={onClickGenerateFromThoughts} disabled={!canGenerateQuestions}>
                Generate questions from thoughts with AI
                <FontAwesomeIcon icon={faRobot} className='ms-1' />
              </Button>
              <Button variant='danger' onClick={onClickDeleteAll} disabled={!canDeleteQuestions}>
                Delete all questions
                <FontAwesomeIcon icon={faTrash} className='ms-1' />
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className='mt-4'>Question list</h2>
            <p>Click on the questions to see the answers.</p>
            <h4>Settings</h4>
            <Form.Switch label='Show only key questions' checked={showOnlyKeyQuestions} onChange={() => setShowOnlyKeyQuestions((v) => !v)} />
            <div className='mb-4' />
            {isLoading ? (
              <Loading page={false} />
            ) : isError ? (
              <Alert variant='danger'>{errorMessage}</Alert>
            ) : !questions.length ? (
              <p className='text-muted fst-italic'>No questions found</p>
            ) : (
              <Accordion defaultActiveKey={questions.map((q) => q.id)}>
                {questionsToShow.map((question, index) => (
                  <QuestionAccordionItem key={question.id} question={question} duration={video.duration} index={index + 1} video={video} />
                ))}
              </Accordion>
            )}
          </Col>
        </Row>
      </Container>
    );

  return null;
};

export default ThoughtQuestions;
