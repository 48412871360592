import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {Video} from '../../types/dtos';
import ReactPlayer from 'react-player';
import {RootState} from '..';
import {Question} from '../../types/question';

interface IInitialState {
  playerRef: any | null;
  isPlaying: boolean;
  isSeeking: boolean;
  volume: number;
  lastVolume: number;
  sliderValue: number;
  playbackRate: number;
  video: Video | null;
  viewMode: string;
  activeQuestions: Question[];
}

const initialState: IInitialState = {
  playerRef: null,
  isPlaying: false,
  isSeeking: false,
  volume: 50,
  lastVolume: 50,
  sliderValue: 0,
  playbackRate: 1,
  video: null,
  viewMode: 'default',
  activeQuestions: [],
};

const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    setPlayerRef(state, action: PayloadAction<ReactPlayer>) {
      state.playerRef = action.payload;
    },
    setIsPlaying(state, action: PayloadAction<boolean>) {
      state.isPlaying = action.payload;
    },
    setIsSeeking(state, action: PayloadAction<boolean>) {
      state.isSeeking = action.payload;
    },
    setVolume(state, action: PayloadAction<number>) {
      state.volume = action.payload;
    },
    setLastVolume(state, action: PayloadAction<number>) {
      state.lastVolume = action.payload;
    },
    setPlaybackRate(state, action: PayloadAction<number>) {
      state.playbackRate = action.payload;
    },
    setSliderValue(state, action: PayloadAction<number>) {
      const second = Math.floor(action.payload);
      state.sliderValue = second;
    },
    initPlayerState(state, action: PayloadAction<Video>) {
      return {...initialState, video: action.payload};
    },
    seekTo: (state, action: PayloadAction<number>) => {
      const offset = state.video?.startSecond || 0;
      const second = action.payload + offset;
      state.playerRef?.seekTo(second);
      state.sliderValue = second;
    },
    setViewMode: (state, action: PayloadAction<string>) => {
      const viewMode = action.payload;
      if (['default', 'fullscreen'].includes(viewMode)) state.viewMode = viewMode;
    },
    pushQuestions: (state, action: PayloadAction<Question[]>) => {
      const questionsToPush = action.payload;
      if (questionsToPush.length > 0) {
        state.activeQuestions = [...state.activeQuestions, ...action.payload];
        //Stop the video
        state.isPlaying = false;
      }
    },
    popAllQuestions: (state) => {
      state.activeQuestions = [];
    },
    removeQuestion: (state, action: PayloadAction<string>) => {
      const questionId = action.payload;
      state.activeQuestions = state.activeQuestions.filter((question) => question.id !== questionId);
    },
    resetVideoState: () => initialState,
  },
});

export const {setPlayerRef, setIsPlaying, setIsSeeking, setVolume, setLastVolume, setPlaybackRate, setSliderValue, initPlayerState, resetVideoState, seekTo, setViewMode, pushQuestions, popAllQuestions, removeQuestion} = playerSlice.actions;

export function selectPlayerInfo(state: RootState) {
  return state.player;
}

export function selectHasQuestionToAsk(state: RootState) {
  return state.player.activeQuestions.length > 0;
}

export function selectActiveQuestions(state: RootState) {
  return state.player.activeQuestions;
}

export default playerSlice.reducer;
