import {useEffect, useState} from 'react';
import {useGetVideoTranscriptQuery} from '../../../../../store/videos/videos.api';
import useError from '../../../../../hooks/useError';
import {TranscriptSegment} from '../../../../../types/dtos';

const useFetch = (videoId: string) => {
  const {data, isLoading, isError, error, isSuccess} = useGetVideoTranscriptQuery(videoId);

  const [transcriptSegments, setTranscriptSegments] = useState<TranscriptSegment[]>([]);

  const errorMessage = useError({isError, error});

  useEffect(() => {
    if (isSuccess) {
      setTranscriptSegments(data);
    }
  }, [data, isSuccess]);

  return {transcriptSegments, isLoading, isError, errorMessage, isSuccess};
};

export default useFetch;
