import React, {useEffect, useMemo} from 'react';
import * as Styles from './styles';
import {Alert, Button, Container} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {useCreatePersonalizedAnswerMutation, useCreateStudentAnswerMutation, useCreateStudentTopicQuestionAnswerMutation} from '../../../../../../store/questions/questions.api';
import {removeQuestion, selectActiveQuestions, selectPlayerInfo, setIsPlaying} from '../../../../../../store/player/player.slice';
import {Answer, Question} from '../../../../../../types/question';
import {Video} from '../../../../../../types/dtos';
interface Props {
  question: Question;
}

const QuestionItem = ({question}: Props) => {
  const dispatch = useDispatch();

  const video = useSelector(selectPlayerInfo).video as Video;
  const [selectedAnswer, setSelectedAnswer] = React.useState<Answer | undefined>();
  const activeQuestions = useSelector(selectActiveQuestions);
  const isLastQuestion = activeQuestions.length === 1;
  const nextButtonText = useMemo(() => (!isLastQuestion ? 'Next' : 'Close'), [isLastQuestion]);

  const [selectAnswerForTranscriptQuestion] = useCreateStudentAnswerMutation();
  const [selectAnswerForTopicQuestion] = useCreateStudentTopicQuestionAnswerMutation();
  const [selectAnswerForThoughtQuestion] = useCreatePersonalizedAnswerMutation();

  //Shuffle answers
  const answers = useMemo(() => {
    const answers = [...question.answers];
    return answers.sort(() => Math.random() - 0.5);
  }, [question.answers]);

  const getClassName = (answer: Answer) => {
    if (!selectedAnswer) return '';
    if (answer.isCorrect) return 'selected-correct';
    if (answer.id === selectedAnswer.id && !answer.isCorrect) return 'selected-wrong';
  };

  const onClickAnswer = (answer: Answer) => {
    if (!selectedAnswer) setSelectedAnswer(answer);

    console.log('video.questionSet', video.questionSet);

    if (video.questionSet === 'trasncript') {
      selectAnswerForTranscriptQuestion({videoId: video.id, answerId: answer.id});
    } else if (video.questionSet === 'topic') {
      selectAnswerForTopicQuestion({videoId: video.id, answerId: answer.id});
    } else if (video.questionSet === 'concept') {
      selectAnswerForThoughtQuestion({videoId: video.id, answerId: answer.id});
    }
  };

  const onClickNextOrClose = () => {
    //If it is the last question, play the video
    if (isLastQuestion) dispatch(setIsPlaying(true));

    dispatch(removeQuestion(question.id));
    setSelectedAnswer(undefined);
  };

  useEffect(() => {
    question.answers.forEach((answer) => {
      if (answer.studentAnswers?.length && answer.studentAnswers?.length > 0) {
        setSelectedAnswer(answer);
      }
    });
  }, [question]);

  return (
    <Container>
      <Styles.Question>{question.text}</Styles.Question>
      {answers.map((answer) => (
        <Styles.Answer disabled={selectedAnswer !== undefined} className={getClassName(answer)} key={answer.id} onClick={() => onClickAnswer(answer)}>
          {answer.text}
        </Styles.Answer>
      ))}
      {selectedAnswer && (
        <>
          <Alert variant={selectedAnswer.isCorrect ? 'success' : 'danger'}>{selectedAnswer.feedback}</Alert>
          {/* Seek back to the video moment to which the question is tagged */}
          <div className='d-flex flex-row justify-content-between gap-2'>
            {/* Button: if there another question then "Next", otw "Close" */}
            <Button variant='success' onClick={onClickNextOrClose}>
              {nextButtonText}
            </Button>
          </div>
        </>
      )}
    </Container>
  );
};

export default QuestionItem;
