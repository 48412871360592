import {Container, Nav} from 'react-bootstrap';
import * as Styles from './styles';
import styled from 'styled-components';

interface PageHeaderProps {
  children: React.ReactNode;
  [x: string]: any;
}

const PageHeader = ({children, ...props}: PageHeaderProps) => {
  return (
    <Styles.Header {...props}>
      <Container>{children}</Container>
    </Styles.Header>
  );
};

PageHeader.Title = styled.h1`
  text-transform: capitalize;
  font-size: 3rem;
`;

PageHeader.Subtitle = styled.span`
  font-size: 1.5rem;
`;

PageHeader.Menu = styled(Nav)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1.125rem;
  margin-bottom: 0;
  font-size: 1rem;
`;

PageHeader.MenuItem = styled(Nav.Item)`
  font-weight: 500;
  cursor: pointer;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  word-wrap: nowrap;

  &.active {
    border-bottom: 0.3rem solid ${(props) => props.theme.textPrimary};
    padding-bottom: 0.2rem;
  }
`;

export default PageHeader;
