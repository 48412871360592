import React from 'react';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';

interface PageSkeletonProps {
  children: React.ReactNode;
}

const PageSkeleton = ({children}: PageSkeletonProps) => {
  return (
    <>
      <Navbar />
      <div className='page-container'>{children}</div>
      <Footer />
    </>
  );
};

export default PageSkeleton;
