import React from 'react';
import Styles from './styles';

interface InputContainerProps {
  formGroupClassName?: string;
  label: string;
  id: string;
  children: React.ReactNode;
}

const InputContainer = ({formGroupClassName, label, id, children}: InputContainerProps) => {
  return (
    <Styles.FormGroup className={formGroupClassName}>
      <Styles.FormLabel htmlFor={id}>{label}</Styles.FormLabel>
      {children}
    </Styles.FormGroup>
  );
};

export default InputContainer;
