import {useRef} from 'react';
import ReactPlayer from 'react-player';
import Controls from './components/controls/Controls';
import QuizModal from './components/quiz-modal/QuizModal';
import useFetch from './hooks/useFetch';
import * as Styles from './styles';
import usePlayer from './hooks/usePlayer';
import {Video} from '../../types/dtos';
import {useNavigate} from 'react-router-dom';

type Props = {
  video: Video;
};

const UncontrollableVideoPlayer = ({video}: Props) => {
  const playerRef = useRef<ReactPlayer>(null);
  const screenfullRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const videoUrl = `https://www.youtube.com/watch?v=${video.idYoutube}&t=${video.startSecond}`;
  //const videoUrl = `https://www.youtube.com/watch?v=icPHcK_cCF4&t=${video.startSecond}`;
  const postTestURL = `/student/watch/${video.id}/post-test`;

  const {questions} = useFetch(video);

  const {onReady, onStart, onPlay, onPause, onProgress, onBuffer, onBufferEnd, onPlaybackRateChange, onMouseEnter, onMouseLeave, onClickPlayer, toggleFullScreen, config, displayControls, volume, playbackRate, isPlaying} = usePlayer({
    playerRef,
    screenfullRef,
    video,
    questions,
  });

  const onEnded = () => {
    navigate(postTestURL);
  };

  return (
    <Styles.StudentVideoPlayer ref={screenfullRef} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Styles.PlayerOverlay onClick={onClickPlayer} />
      <ReactPlayer ref={playerRef} url={videoUrl} controls={false} playing={isPlaying} progressInterval={500} volume={volume} playbackRate={playbackRate} config={config} onStart={onStart} onReady={onReady} onPause={onPause} onPlay={onPlay} onProgress={onProgress} onBuffer={onBuffer} onBufferEnd={onBufferEnd} onPlaybackRateChange={onPlaybackRateChange} onEnded={onEnded} />
      {displayControls && <Controls questions={questions} toggleFullScreen={toggleFullScreen} />}
      <QuizModal toggleFullScreen={toggleFullScreen} />
    </Styles.StudentVideoPlayer>
  );
};

export default UncontrollableVideoPlayer;
