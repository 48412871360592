import {Thought} from '../../types/dtos';
import {MessageResponse} from '../../types/response';
import {apiSlice} from '../api/api.slice';

const thoughtsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //Generate thoughts
    generateThoughts: builder.mutation<Thought[], string>({
      query: (videoId) => ({
        url: `thoughts/generate/${videoId}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, videoId) => [{type: 'Thoughts', id: videoId}],
    }),
    //Get video thoughts
    getVideoThoughts: builder.query<Thought[], string>({
      query: (videoId) => `thoughts/${videoId}`,
      providesTags: (result, error, videoId) => [{type: 'Thoughts', id: videoId}],
    }),
    //Delete all thoughts for video
    deleteAllThoughtsForVideo: builder.mutation<MessageResponse, string>({
      query: (videoId) => ({
        url: `thoughts/delete-all/${videoId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, videoId) => [{type: 'Thoughts', id: videoId}],
    }),
  }),
});

export const {useGenerateThoughtsMutation, useGetVideoThoughtsQuery, useDeleteAllThoughtsForVideoMutation} = thoughtsApiSlice;
