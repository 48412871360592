import React from 'react';
import * as Styles from './styles';

interface PanelProps {
  children: React.ReactNode;
  [x: string]: any;
}

const Panel = ({children, ...props}: PanelProps) => {
  return <Styles.Panel {...props}>{children}</Styles.Panel>;
};

export default Panel;
