import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {selectPlayerInfo} from '../../../store/player/player.slice';

const useGetVideoSecond = () => {
  const {playerRef, video} = useSelector(selectPlayerInfo);

  const getVideoSecond = useCallback(() => {
    const youtubeSecond = playerRef?.getCurrentTime();
    const startSecond = video?.startSecond || 0;
    if (youtubeSecond == null || !startSecond === null) return 0;
    return youtubeSecond - startSecond;
  }, [playerRef, video]);

  return getVideoSecond;
};

export default useGetVideoSecond;
