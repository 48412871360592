import {useMemo} from 'react';
import {Navigate, Outlet, useOutletContext, useParams} from 'react-router-dom';
import PageSkeleton from '../../components/page-skeleton/PageSkeleton';
import PageHeader from '../../components/page-header/PageHeader';
import useMenu from '../../hooks/useMenu';
import useFetch from './hooks/useFetch';
import Loading from '../../components/loading/Loading';
import {Video} from '../../types/dtos';

type ContextType = {
  video: Video;
};

const TeacherVideo = () => {
  const {videoId = ''} = useParams();
  const linkList = useMemo(
    () => [
      {
        name: 'Edit',
        path: `/creator/my-video/${videoId}`,
        pattern: `/creator/my-video/${videoId}`,
      },
      {
        name: 'Transcript',
        path: `/creator/my-video/${videoId}/transcript`,
        pattern: `/creator/my-video/${videoId}/transcript`,
      },
      {
        name: 'Transcript Questions',
        path: `/creator/my-video/${videoId}/transcript-questions`,
        pattern: `/creator/my-video/${videoId}/transcript-questions`,
      },
      {
        name: 'Topics',
        path: `/creator/my-video/${videoId}/topics`,
        pattern: `/creator/my-video/${videoId}/topics`,
      },
      {
        name: 'Topic Questions',
        path: `/creator/my-video/${videoId}/topic-questions`,
        pattern: `/creator/my-video/${videoId}/topic-questions`,
      },
      {
        name: 'Concepts',
        path: `/creator/my-video/${videoId}/concepts`,
        pattern: `/creator/my-video/${videoId}/concepts`,
      },
      {
        name: 'Concept Questions',
        path: `/creator/my-video/${videoId}/concept-questions`,
        pattern: `/creator/my-video/${videoId}/concept-questions`,
      },
    ],
    [videoId]
  );

  const {active, handleMenuClick} = useMenu({linkList});

  const {isError, isLoading, isSuccess, video} = useFetch(videoId);

  if (isLoading) return <Loading />;
  if (isError) return <Navigate to='/creator' />;
  if (isSuccess && video)
    return (
      <PageSkeleton>
        <PageHeader>
          <PageHeader.Title>{video.name}</PageHeader.Title>
          <PageHeader.Menu>
            {linkList.map((link, index) => (
              <PageHeader.MenuItem key={index} className={active === index ? 'active' : ''} onClick={() => handleMenuClick(index)}>
                {link.name}
              </PageHeader.MenuItem>
            ))}
          </PageHeader.Menu>
        </PageHeader>
        {/* Pass the video as outlet context */}
        <Outlet context={{video} satisfies ContextType} />
      </PageSkeleton>
    );

  return null;
};

export const useVideo = () => {
  return useOutletContext<ContextType>();
};

export default TeacherVideo;
