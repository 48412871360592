import styled from 'styled-components';
import {Card} from 'react-bootstrap';

export const VideoCard = styled(Card)`
  margin-bottom: 1.25rem;
  border: none;
`;

export const ThumbnailContainer = styled.div`
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
`;

export const TimeContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
`;

export const VideoCardImg = styled(Card.Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const VideoCardBody = styled(Card.Body)`
  padding: 0.5rem 0;
`;

export const VideoCardTitle = styled(Card.Title)`
  font-size: 1rem;
  font-weight: 500;
  /* Max two row and then ellipsis */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const VideoCardText = styled(Card.Text)`
  font-size: 0.875rem;
  color: #6c757d;
  margin-bottom: 0.25rem;
`;
