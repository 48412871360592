import styled from 'styled-components';

export const VideoControlsContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  color: white;
  z-index: 100;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.75), transparent);
    width: 100%;
    aspect-ratio: 6 / 1;
    z-index: -1;
    pointer-events: none;
  }

  .timeline {
    position: relative;
    display: flex;
    align-items: center;
    background-color: ${(p) => p.theme.textPrimary};
    opacity: 0.85;
    margin: 0 0.3rem;
    height: 7px;
    z-index: 100;

    .range-slider__wrap {
      width: 100%;
      height: 100%;
    }

    .range-slider__tooltip__label {
      display: none;
    }

    input[type='range'] {
      position: absolute;
      top: 50%;
      transform: translateY(-50%) !important;
      height: 100%;
      width: 100%;
      padding: 0px;

      -webkit-appearance: none;
      background-color: transparent;

      /* CHROME */
      &::-webkit-slider-runnable-track {
        background-color: transparent;
        -webkit-appearance: none;
      }

      &::-webkit-slider-thumb {
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 50%;
        -webkit-appearance: none;
        background-color: ${(p) => p.theme.accent};
      }

      /* FF */
      &::-moz-range-progress {
        -webkit-appearance: none;
        background-color: transparent;
      }

      &::-moz-range-track {
        background-color: transparent;
      }

      /* IE*/
      input[type='range']::-ms-fill-lower {
        background-color: transparent;
      }
      input[type='range']::-ms-fill-upper {
        background-color: transparent;
      }
    }
  }
`;

export const TimeSection = styled.div<{left: number; width: number; active: boolean}>`
  position: absolute;

  height: 100%;
  left: ${(props) => props.left}%;
  width: ${(props) => props.width}%;

  ${(props) => (props.active ? `background: linear-gradient(270deg, ${props.theme.accent}, ${props.color});` : `background-color: ${props.color};`)}
  background-size: 400% 400%;

  border-left: 3px solid ${(p) => p.theme.primary};
  ${(props) => props.active && 'animation: animate 1.5s ease infinite;'}

  cursor: pointer;

  @keyframes animate {
    0% {
      background-position: 0% 50%;
      height: 100%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

export const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 1rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-size: 1.1rem;

  /* All the control icons */
  i {
    padding: 0.3rem;
    font-size: 1.5rem;
    color: inherit;
    cursor: pointer;
    opacity: 0.85;
    transition: opacity 150ms ease-in-out;

    &:hover {
      opacity: 1;
    }
  }

  .action-btn {
    cursor: pointer;
    opacity: 0.85;
    transition: opacity 150ms ease-in-out;

    &:hover {
      opacity: 1;
    }
  }

  .start {
    display: flex;
    gap: 0.75rem;
    align-items: center;
    color: inherit;

    .lesson-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 30rem;

      /* If device width is less than 961px, hide the lesson name */
      @media (max-width: 961px) {
        display: none;
      }
    }
  }
`;

export const VolumeContainer = styled.div`
  display: flex;
  align-items: center;

  .range-slider__wrap {
    width: 0;
    transform-origin: left;
    transform: scaleX(0);
    transition: width 150ms ease-in-out, transform 150ms ease-in-out;
  }

  &:hover .range-slider__wrap,
  .range-slider__wrap:focus-within {
    width: 5rem;
    margin-left: 0.5rem;

    input {
      width: 5rem;
    }

    transform: scaleX(1);
  }
`;

export const DurationContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
`;
