import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {authApiSlice} from './auth.api';
import {RootState} from '..';
import {User} from '../../types/dtos';

interface IInitialState {
  user: User | null;
  accessToken: string | null;
  prolificId: string | null;
}

const initialState: IInitialState = {
  user: null,
  accessToken: null,
  prolificId: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, {payload}: PayloadAction<{user: User; accessToken: string}>) => {
      const {user, accessToken} = payload;
      state.user = user;
      state.accessToken = accessToken;
    },
    refreshAccessToken: (state, {payload}: PayloadAction<{accessToken: string}>) => {
      state.accessToken = payload.accessToken;
    },
    resetProlificId: (state, {payload}: PayloadAction<{prolificId: string}>) => {
      state.prolificId = payload.prolificId;
    },
    resetCredentials: (state) => {
      state.user = null;
      state.accessToken = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApiSlice.endpoints.login.matchFulfilled, (state, action) => {
      const {user, accessToken} = action.payload;
      state.user = user;
      state.accessToken = accessToken;
    });
    builder.addMatcher(authApiSlice.endpoints.logout.matchFulfilled, (state) => {
      state.user = null;
      state.accessToken = null;
    });
  },
});

export const {setCredentials, refreshAccessToken, resetCredentials, resetProlificId} = authSlice.actions;

export function selectUser(state: RootState) {
  return state.auth.user;
}
export function selectIsUserLogged(state: RootState) {
  return state.auth.accessToken !== null;
}

export const selectAccessToken = (state: RootState) => state.auth.accessToken;

export const selectProlificId = (state: RootState) => state.auth.prolificId;

export default authSlice.reducer;
