import Styles from './styles';
import {toHHMMSS} from '../../utils/time';

const Thumb = (props: Object, state: {index: number; value: number | readonly number[]; valueNow: number}, showText = false) => {
  return <Styles.Thumb {...props}>{showText && toHHMMSS(state.valueNow)}</Styles.Thumb>;
};

const Track = (props: Object, state: {index: number; value: number | readonly number[]}) => <Styles.Track {...props} index={state.index} />;

interface IntervalSliderProps {
  from: number;
  to: number;
  max: number;
}

const IntervalSlider = ({from, to, max}: IntervalSliderProps) => {
  return (
    <div className='d-flex flex-row justify-content-between align-items-center gap-2'>
      <Styles.Label>{toHHMMSS(from)}</Styles.Label>
      <Styles.Slider defaultValue={[from, to]} renderTrack={Track} renderThumb={Thumb} max={max} disabled />
      <Styles.Label>{toHHMMSS(to)}</Styles.Label>
    </div>
  );
};

export default IntervalSlider;
