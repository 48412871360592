function getPassedTimeInString(date: Date | string) {
  date = new Date(date);
  const now = new Date();
  const diff = now.getTime() - date.getTime();

  //Seconds
  const seconds = Math.floor(diff / 1000);
  if (seconds < 5) return 'Now';
  if (seconds < 60) return `${seconds} seconds ago`;

  //Minutes
  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) return `${minutes} minutes ago`;

  //Hours
  const hours = Math.floor(minutes / 60);
  if (hours < 24) return `${hours} hours ago`;

  //Days
  const days = Math.floor(hours / 24);
  if (days < 7) return `${days} days ago`;

  //Weeks
  const weeks = Math.floor(days / 7);
  if (weeks < 4) return `${weeks} weeks ago`;

  //Months
  const months = Math.floor(days / 30);
  if (months < 12) return `${months} months ago`;

  //Years
  const years = Math.floor(months / 12);
  return `${years} years ago`;
}

function toHHMMSS(secs: number, decimalDigits = 0) {
  const secNum = parseInt(secs.toString(), 10);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) % 60;
  const seconds = secNum % 60;
  const decimals = secs % 1;

  return [hours, minutes, seconds]
    .map((val) => (val < 10 ? `0${val}` : val))
    .filter((val, index) => val !== '00' || index > 0)
    .join(':')
    .replace(/^0/, '')
    .concat(decimalDigits > 0 ? `.${decimals.toFixed(decimalDigits).slice(2)}` : '');
}

function toHHMM(secs: number) {
  const secNum = parseInt(secs.toString(), 10);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) % 60;

  return [hours, minutes]
    .map((val) => (val < 10 ? `0${val}` : val))
    .filter((val, index) => val !== '00' || index > 0)
    .join(':')
    .replace(/^0/, '');
}

function toHoursMinsSecs(secs: number) {
  const hours = Math.floor(secs / 3600);
  const minutesLeft = Math.floor((secs - hours * 3600) / 60);
  const secondsLeft = secs - hours * 3600 - minutesLeft * 60;
  if (hours > 0) return `${hours}h ${minutesLeft}min`;
  if (minutesLeft > 0) return `${minutesLeft}min`;
  return `${secondsLeft}sec`;
}

function getSecondsFromHHMMSS(value: string) {
  const [str1, str2, str3] = value.split(':');

  const val1 = Number(str1);
  const val2 = Number(str2);
  const val3 = Number(str3);

  if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
    // seconds
    return val1;
  }

  if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
    // minutes * 60 + seconds
    return val1 * 60 + val2;
  }

  if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
    // hours * 60 * 60 + minutes * 60 + seconds
    return val1 * 60 * 60 + val2 * 60 + val3;
  }

  return 0;
}

export {getSecondsFromHHMMSS, getPassedTimeInString, toHHMMSS, toHHMM, toHoursMinsSecs};
