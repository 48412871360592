import {Navbar as BNavbar, Container, Nav, NavDropdown} from 'react-bootstrap';
import * as Styles from './styles';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {resetCredentials, selectIsUserLogged, selectUser} from '../../store/auth/auth.slice';
import Loading from '../loading/Loading';
import {useLogoutMutation} from '../../store/auth/auth.api';

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLogged = useSelector(selectIsUserLogged);
  const user = useSelector(selectUser);

  const [logout, {isLoading}] = useLogoutMutation();

  const onLogout = () => {
    console.log('Logging out');
    logout()
      .unwrap()
      .then(() => {
        console.log('Logged out');
      })
      .catch(() => {
        console.log('Error logging out');
        dispatch(resetCredentials());
      })
      .finally(() => {
        navigate('/');
      });
  };

  if (isLoading) return <Loading />;
  return (
    <Styles.StyledNavbar expand='lg'>
      <Container>
        <BNavbar.Brand href='/'>SmartQuiz</BNavbar.Brand>
        <BNavbar.Toggle aria-controls='navbar-nav' />
        <BNavbar.Collapse id='navbar-nav'>
          <Nav className='ms-auto'>
            <Nav.Link href='/'>Home</Nav.Link>
            {isLogged && user ? (
              <>
                <Nav.Link href='/creator'>Creator studio</Nav.Link>
                <Nav.Link href='/student'>Student dashboard</Nav.Link>
                <NavDropdown title={user.username} id='navbar-dropdown'>
                  {/* Logout */}
                  <NavDropdown.Item onClick={onLogout}>Logout</NavDropdown.Item>
                </NavDropdown>
              </>
            ) : (
              <Nav.Link href='/'>Login</Nav.Link>
            )}
          </Nav>
        </BNavbar.Collapse>
      </Container>
    </Styles.StyledNavbar>
  );
};

export default Navbar;
