import styled from 'styled-components';
import {Form} from 'react-bootstrap';

const Styles = {
  FormGroup: styled(Form.Group)`
    position: relative;
  `,

  FormLabel: styled(Form.Label)`
    z-index: 1;
    position: absolute;
    margin-top: -1rem;
    margin-left: 1.5rem;
    background-color: ${(props) => (props.dark ? props.theme.primary : props.theme.textPrimary)};
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.875rem;
    border-radius: 99em;
    text-transform: capitalize;
    color: ${(props) => (props.dark ? props.theme.textPrimary : props.theme.primary)};
  `,
};
export default Styles;
