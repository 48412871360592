import styled from 'styled-components';

export const Answer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.5rem;

  .half {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: start;
  }
`;
