import React, {useEffect, useRef} from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import {useRateThoughtQuestionMutation} from '../../../../../store/questions/questions.api';
import toast from 'react-hot-toast';
import Loading from '../../../../../components/loading/Loading';
import {ThoughtQuestion} from '../../../../../types/dtos';

interface BadRatingModalProps {
  show: boolean;
  onHide: () => void;
  resetQuestion: () => void;
  question: ThoughtQuestion;
}

const BadRatingModal = ({show, onHide, resetQuestion, question}: BadRatingModalProps) => {
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<number | undefined>();
  const [motivation, setMotivation] = React.useState<string>('');
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const canSubmit = selectedCheckBox !== undefined && (selectedCheckBox !== 5 || motivation.trim().length > 0);
  const MOTIVATION_MAX_LENGTH = 500;
  const badRatingOptions = ['not_clear', 'not_relevant', 'wrong_answer', 'more_than_one_correct', 'other'];

  const [rateQuestion, {isLoading}] = useRateThoughtQuestionMutation();

  const onSubmit = () => {
    if (canSubmit) {
      const motivationShort = motivation.length > MOTIVATION_MAX_LENGTH ? motivation.substring(0, MOTIVATION_MAX_LENGTH) : motivation;

      const data = {
        questionId: question.id,
        isPositive: false,
        badReason: badRatingOptions[selectedCheckBox],
        badComment: selectedCheckBox === 4 ? motivationShort : undefined,
      };

      rateQuestion(data)
        .unwrap()
        .then(() => {
          toast.success('Thank you for your feedback!');
          resetQuestion();
          onHide();
        })
        .catch(() => {
          toast.error('An error occurred while sending your feedback');
        });
    }
  };

  const selectCheckbox = (id: number) => {
    setSelectedCheckBox(id);
    if (id === 5) textAreaRef.current?.focus();
  };

  useEffect(() => {
    setSelectedCheckBox(undefined);
    setMotivation('');
  }, [show]);

  const onChangeTextArea: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setMotivation(e.target.value);
    setSelectedCheckBox(4);
  };

  if (isLoading) return <Loading page={true} />;
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Sorry, you rated this question as bad</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Would you like to tell us why?</p>
        {/* Start of radio check boxes */}
        <Form.Check type='radio' label='Question is not clear' name='ratingRadios' id='ratingRadios1' onClick={() => selectCheckbox(0)} checked={selectedCheckBox === 0} />
        <Form.Check type='radio' label='Question is not relevant for the video' name='ratingRadios' id='ratingRadios2' onClick={() => selectCheckbox(1)} checked={selectedCheckBox === 1} />
        <Form.Check type='radio' label='The answer is wrong' name='ratingRadios' id='ratingRadios3' onClick={() => selectCheckbox(2)} checked={selectedCheckBox === 2} />
        <Form.Check type='radio' label='More than one answer is correct' name='ratingRadios' id='ratingRadios4' onClick={() => selectCheckbox(3)} checked={selectedCheckBox === 3} />
        <Form.Check type='radio' label='Other' name='ratingRadios' id='ratingRadios5' onClick={() => selectCheckbox(4)} checked={selectedCheckBox === 4} />
        <Form.Control ref={textAreaRef} className='mt-2' as='textarea' placeholder='Please, provide more details' value={motivation} onChange={onChangeTextArea} maxLength={MOTIVATION_MAX_LENGTH} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHide}>
          Close
        </Button>
        {/* End of radio check boxes */}
        <Button onClick={onSubmit} disabled={!canSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BadRatingModal;
