import React from 'react';
import {getMessageFromError} from '../utils/errors';
import {FetchBaseQueryError} from '@reduxjs/toolkit/query';
import {SerializedError} from '@reduxjs/toolkit';

interface UseErrorProps {
  isError: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
}

const useError = ({isError, error}: UseErrorProps) => {
  const [errorMessage, setErrorMessage] = React.useState<string>();

  //Check if there is an error
  React.useEffect(() => {
    if (isError && error) {
      const message = getMessageFromError(error);
      console.log(message);
      console.log(getMessageFromError(error));
      setErrorMessage(getMessageFromError(error));
    }
  }, [isError, error, errorMessage]);

  return errorMessage;
};

export default useError;
