import React, {useState} from 'react';
import PageSkeleton from '../../components/page-skeleton/PageSkeleton';
import {Col, Container, Row} from 'react-bootstrap';
import {Navigate, useParams} from 'react-router-dom';
import QuestionGenerator from './components/question-generator/QuestionGenerator';
import Question from './components/question/Question';
import useFetch from './hooks/useFetch';
import Loading from '../../components/loading/Loading';
import ViewsInfo from './components/views-info/ViewsInfo';
import {ThoughtQuestion} from '../../types/dtos';

const StudentQuiz = () => {
  const params = useParams();
  const videoId = params.videoId || '';

  const [question, setQuestion] = useState<ThoughtQuestion | undefined>();

  const {video, isLoading, isError, isSuccess} = useFetch(videoId);

  const resetQuestion = () => setQuestion(undefined);

  if (isLoading) return <Loading page={true} />;
  return (
    <>
      {isError && <Navigate to='/student' />}
      {isSuccess && video && (
        <PageSkeleton>
          <Container className='py-4'>
            <Row>
              <Col xs={12} sm={{offset: 2, span: 8}}>
                <ViewsInfo video={video} />
                <div className='mb-4' />
                {question ? <Question question={question} resetQuestion={resetQuestion} /> : <QuestionGenerator video={video} setQuestion={setQuestion} />}
              </Col>
            </Row>
          </Container>
        </PageSkeleton>
      )}
    </>
  );
};

export default StudentQuiz;
