import {useEffect, useState} from 'react';
import useError from '../../../../../hooks/useError';
import {useGetVideoTopicsQuery} from '../../../../../store/topics/topics.api';
import {Topic} from '../../../../../types/dtos';

const useFetch = (videoId: string) => {
  const {data, isFetching, isError, error, isSuccess} = useGetVideoTopicsQuery(videoId);

  const [topics, setTopics] = useState<Topic[]>([]);

  const errorMessage = useError({isError, error});

  useEffect(() => {
    if (isSuccess) setTopics(data);
  }, [data, isSuccess]);

  return {topics, isFetching, isError, isSuccess, errorMessage};
};

export default useFetch;
