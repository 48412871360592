import React, {useState} from 'react';
import {Alert, Button, Container, Form} from 'react-bootstrap';
import {useAddVideoMutation} from '../../../../store/videos/videos.api';
import {toastSuccess} from '../../../../utils/toast';
import {useNavigate} from 'react-router-dom';
import {getMessageFromError} from '../../../../utils/errors';
import InputContainer from '../../../../components/form-control/InputContainer';

const AddVideo = () => {
  const navigate = useNavigate();
  const [addVideo, {isLoading}] = useAddVideoMutation();

  const [videoCode, setVideoCode] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const canSubmit = videoCode.trim().length > 0 && !isLoading;

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (canSubmit) {
      addVideo(videoCode)
        .unwrap()
        .then(() => {
          setErrorMessage(undefined);
          toastSuccess('Video added to your library');
          navigate('/student');
        })
        .catch((err) => {
          setErrorMessage(getMessageFromError(err));
        });
    }
  };

  return (
    <Container className='py-5'>
      <h2>Add a new video to your Library</h2>
      <p>Paste here the video code to add the video to your library. The video code can be shared by your teacher.</p>
      <Form onSubmit={onSubmit}>
        <InputContainer formGroupClassName={'mt-4 mb-3'} label='Video code' id='videoCode'>
          <Form.Control type='text' value={videoCode} onChange={(e) => setVideoCode(e.target.value)} placeholder='Insert video code here' />
        </InputContainer>
        {errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}
        <Button disabled={!canSubmit} type='submit'>
          Add video
        </Button>
      </Form>
    </Container>
  );
};

export default AddVideo;
