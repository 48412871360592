import React from 'react';
import Panel from '../../../../components/panel/Panel';
import {Button} from 'react-bootstrap';
import {useLazyGetQuestionAboutAnnotationQuery, useLazyGetUnwatchedQuestionQuery, useLazyGetWatchedQuestionQuery} from '../../../../store/personalized-quiz/personalizedQuiz.api';
import toast from 'react-hot-toast';
import {getMessageFromError} from '../../../../utils/errors';
import Loading from '../../../../components/loading/Loading';
import {ThoughtQuestion, Video} from '../../../../types/dtos';

interface QuestionGeneratorProps {
  video: Video;
  setQuestion: React.Dispatch<React.SetStateAction<ThoughtQuestion | undefined>>;
}

const QuestionGenerator = ({video, setQuestion}: QuestionGeneratorProps) => {
  const [getUnwatchedQuestion, {isFetching: isFetching1}] = useLazyGetUnwatchedQuestionQuery();
  const [getWatchedQuestion, {isFetching: isFetching2}] = useLazyGetWatchedQuestionQuery();
  const [getQuestionAboutAnnotation, {isFetching: isFetching3}] = useLazyGetQuestionAboutAnnotationQuery();
  const isFetching = isFetching1 || isFetching2 || isFetching3;

  const onClickButton = (buttonId: number, annotationType: 'important' | 'easy' | 'difficult' | undefined) => {
    if (buttonId < 3) {
      getQuestionAboutAnnotation({videoId: video.id, annotationType})
        .unwrap()
        .then((res) => {
          console.log(res);
          if (!res) toast.error('Your annotations are not enough to generate a question. Please add more annotations.');
          else setQuestion(res);
        })
        .catch((err) => {
          toast.error(getMessageFromError(err));
        });
    } else if (buttonId === 3) {
      getWatchedQuestion(video.id)
        .unwrap()
        .then((res) => {
          if (!res) toast.error('You have not watched this video yet. Please watch the video first.');
          else setQuestion(res);
        })
        .catch((err) => {
          toast.error(getMessageFromError(err));
        });
    } else if (buttonId === 4) {
      getUnwatchedQuestion(video.id)
        .unwrap()
        .then((res) => {
          if (!res) toast.error('You have already watched this video. Please watch another video.');
          else setQuestion(res);
        })
        .catch((err) => {
          toast.error(getMessageFromError(err));
        });
    }
  };

  if (isFetching) return <Loading />;
  return (
    <Panel>
      <h2 className='fs-5'>Question Generator</h2>
      <p className='text-muted'>Generate questions based on your feedback and your watching behavior.</p>
      <p>Generate a question about...</p>
      <div className='d-flex flex-column gap-2'>
        <Button variant='primary' className='mx-3' onClick={() => onClickButton(0, 'important')}>
          Something important
        </Button>
        <Button variant='success' className='mx-3' onClick={() => onClickButton(1, 'easy')}>
          Something easy
        </Button>
        <Button variant='danger' className='mx-3' onClick={() => onClickButton(2, 'difficult')}>
          Something hard
        </Button>
        <Button variant='warning' className='mx-3' onClick={() => onClickButton(3, undefined)}>
          Something I watched
        </Button>
        <Button variant='primary' className='mx-3' onClick={() => onClickButton(4, undefined)}>
          Something I didn't watch
        </Button>
      </div>
    </Panel>
  );
};

export default QuestionGenerator;
