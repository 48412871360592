import React, {useEffect, useState} from 'react';
import {Button, Container, Row, Col, Form, Alert} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import getVideoId from 'get-video-id';
import ReactPlayer from 'react-player';
import VideoContainer from '../../../../components/video-container/VideoContainer';
import IntervalVideoPlayer from '../../../../components/interval-video-player/IntervalVideoPlayer';
import {Link} from 'react-router-dom';
import InputContainer from '../../../../components/form-control/InputContainer';
import {useCreateVideoMutation} from '../../../../store/videos/videos.api';
import Loading from '../../../../components/loading/Loading';
import {getMessageFromError} from '../../../../utils/errors';
import toast from 'react-hot-toast';

const NewVideo = () => {
  const navigate = useNavigate();
  const [createVideo, {isLoading}] = useCreateVideoMutation();

  const [name, setName] = useState<string>('');
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [videoId, setVideoId] = useState<string>('');
  const [startSecond, setStartSecond] = useState<number>(0);
  const [endSecond, setEndSecond] = useState<number>(1);
  const [duration, setDuration] = useState<number>(1);
  const [canPlayVideo, setCanPlayVideo] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<undefined | string>(undefined);
  const isSavedDisabled = !name || !videoUrl || !videoId || !canPlayVideo;

  const onChangeVideoURLHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;
    setVideoUrl(value);
    const {id, service} = getVideoId(value);
    if (service === 'youtube' && id) {
      setVideoId(id);
    } else {
      setVideoId('');
    }
  };

  const onFormSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (isSavedDisabled) return;
    // Save video
    createVideo({name, idYoutube: videoId, startSecond, endSecond})
      .unwrap()
      .then(() => {
        toast.success('Video created successfully');
        navigate('/creator');
      })
      .catch((err) => {
        setErrorMessage(getMessageFromError(err));
      });
  };

  useEffect(() => {
    const canPlay = ReactPlayer.canPlay(videoUrl);
    setCanPlayVideo(canPlay);
    if (canPlay) {
      setStartSecond(0);
      setEndSecond(duration);
    } else {
      setStartSecond(0);
      setEndSecond(1);
    }
  }, [videoUrl, duration]);

  if (isLoading) return <Loading page={true} />;
  return (
    <Form onSubmit={onFormSubmit}>
      <Container className='py-5'>
        <Row className='mb-4'>
          <h2 className='mb-5'>Configure a new video lesson</h2>
          {/* Video name */}
          <InputContainer label='Video name' formGroupClassName='mb-4' id='video-name'>
            <Form.Control type='text' placeholder='Enter video name' value={name} onChange={(e) => setName(e.target.value)} maxLength={200} />
          </InputContainer>
          {/* YouTube video URL */}
          <InputContainer label='YouTube video URL' formGroupClassName='mb-2' id='video-url'>
            <Form.Control type='text' placeholder='Enter YouTube video URL' value={videoUrl} onChange={onChangeVideoURLHandler} maxLength={200} />
          </InputContainer>
        </Row>
        {/* Video ID */}
        <Row>
          <Col>
            <h3 className='mb-3'>Video preview</h3>
            {!videoUrl && <p className='text-muted'>Enter a YouTube video URL to preview it here</p>}
          </Col>
        </Row>

        {videoUrl && canPlayVideo && (
          <Row>
            <Col xs={12} sm={{span: 8, offset: 2}}>
              <VideoContainer containerClassName='mb-4'>
                <IntervalVideoPlayer end={endSecond} start={startSecond} videoId={videoId} setDuration={setDuration} />
              </VideoContainer>
            </Col>
          </Row>
        )}
        {videoUrl && !canPlayVideo && (
          <Row>
            <Col>
              <Alert variant='danger'>Invalid video URL</Alert>
            </Col>
          </Row>
        )}
        {errorMessage && (
          <Row>
            <Alert variant='danger'>{errorMessage}</Alert>
          </Row>
        )}
        <Row>
          <div className='d-flex justify-content-end gap-2 mt-3'>
            <Link to='/creator'>
              <Button variant='secondary'>Cancel</Button>
            </Link>
            <Button variant='success' type='submit' disabled={isSavedDisabled}>
              Save
            </Button>
          </div>
        </Row>
      </Container>
    </Form>
  );
};

export default NewVideo;
