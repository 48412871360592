import {useSelector} from 'react-redux';
import {selectSocket} from '../../../../../store/socket/socket.slice';
import {Socket} from 'socket.io-client';

const useLogEvent = () => {
  const socket = useSelector(selectSocket) as Socket;

  const logPlayEvent = (second: number, playbackRate: number) => {
    const startSecond = Math.floor(second);
    socket.emit('logs/play', {startSecond, playbackRate});
    console.log('play: ', startSecond, 'playbackRate: ', playbackRate);
  };

  const logPauseEvent = (second: number) => {
    const startSecond = Math.floor(second);
    socket.emit('logs/pause', {startSecond});
    console.log('pause: ', startSecond);
  };

  const logPlaybackRateChange = (second: number, playbackRate: number) => {
    const startSecond = Math.floor(second);
    socket.emit('logs/speed-change', {startSecond, playbackRate});
    console.log('playBackRateChange: ', startSecond, 'playbackRate: ', playbackRate);
  };

  const logSeekEvent = (secondFrom: number, secondTo: number) => {
    const startSecond = Math.floor(secondFrom);
    const endSecond = Math.floor(secondTo);
    if (secondFrom < secondTo) {
      socket.emit('logs/seek-forward', {startSecond, endSecond});
      console.log('seek-forward: ', secondFrom, secondTo);
    } else if (secondFrom > secondTo) {
      socket.emit('logs/seek-backward', {startSecond, endSecond});
      console.log('seek-backward: ', secondFrom, secondTo);
    }
  };

  const logEndEvent = () => {
    socket.emit('logs/end');
    console.log('end');
  };

  const logBuffering = (second: number, bufferingDuration: number) => {
    const startSecond = Math.floor(second);
    socket.emit('logs/buffering', {startSecond, bufferingDuration});
    console.log('buffering: ', startSecond, 'bufferingDuration: ', bufferingDuration);
  };

  return {logPlayEvent, logPauseEvent, logPlaybackRateChange, logSeekEvent, logEndEvent, logBuffering};
};

export default useLogEvent;
