import {useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {findQuestionsToAsk} from '../../../utils/questions';
import screenfull from 'screenfull';
import useLogEvent from '../../../pages/student-video/outlets/video-with-personalization/hooks/useLogEvent';
import useGetVideoSecond from './useGetVideoSecond';
import {Video} from '../../../types/dtos';
import ReactPlayer from 'react-player';
import {pushQuestions, seekTo, selectPlayerInfo, setIsPlaying, setPlayerRef, setSliderValue, setViewMode} from '../../../store/player/player.slice';
import {Question} from '../../../types/question';

interface IUsePlayer {
  playerRef: React.RefObject<ReactPlayer>;
  screenfullRef: React.RefObject<HTMLDivElement>;
  video: Video;
  questions: Question[];
}

const usePlayer = ({playerRef, screenfullRef, video, questions}: IUsePlayer) => {
  const dispatch = useDispatch();

  const [videoStarted, setVideoStarted] = useState<boolean>(false);
  const [lastSecond, setLastSecond] = useState<number>(video.startSecond);
  const [buffering, setBuffering] = useState<boolean>(false);
  const [bufferingStartTime, setBufferingStartTime] = useState<number>(0);
  const [mouseIn, setMouseIn] = useState<boolean>(false);
  const {logPlayEvent, logPauseEvent, logPlaybackRateChange, logEndEvent, logBuffering} = useLogEvent();
  const MAX_ABS_DELTA_TIME = 3.0; //2 is ok, but 3 is better

  const {isPlaying, playbackRate, isSeeking, volume} = useSelector(selectPlayerInfo);
  const displayControls = videoStarted && (mouseIn || !isPlaying);

  const getVideoSecond = useGetVideoSecond();

  const config = {
    youtube: {
      height: '100%',
      playerVars: {
        cc_load_policy: 1, //Hide closed captions
        cc_lang_pref: 'en', //Set closed captions language
        controls: 1, //Don't show controls
        disablekb: 1, //Disable keyboard controls
        fs: 0, //Disable fullscreen
        modestbranding: 1, //Hide youtube logo
        rel: 0, //Don't show related videos
        showinfo: 0, //Don't show video info
        start: video.startSecond, //Start second
        end: video.endSecond, //End second
      },
    },
  };

  const onReady = () => {
    //Register playerRef to redux store
    if (playerRef.current) dispatch(setPlayerRef(playerRef.current));
  };

  const onStart = () => {
    //If the video is started, set the videoStarted state to true
    setVideoStarted(true);
    //Seek to the start second
    dispatch(seekTo(0));
  };

  const onPlay = () => {
    let second = getVideoSecond();
    //Check if the second is ok
    if (second < 0) second = 0;
    //Log the play event
    logPlayEvent(second, playbackRate);
  };

  const onPause = () => {
    //Log the pause event only if the video is not seeking
    const second = Math.floor(getVideoSecond());
    if (!isSeeking && second >= 0 && second <= video.duration) logPauseEvent(second);
  };

  const onPlaybackRateChange = (newPlaybackRate: number) => {
    const second = Math.floor(getVideoSecond());
    //Log the playback rate change event
    logPlaybackRateChange(second, newPlaybackRate);
  };

  const onBuffer = () => {
    const time = new Date().getTime();
    setBufferingStartTime(time);
    setBuffering(true);
  };

  const onBufferEnd = () => {
    //When the video is loaded the buffer ends without calling the previous onBuffer event
    const second = Math.floor(getVideoSecond());
    let bufferingDuration = 0;
    if (buffering) {
      const time = new Date().getTime();
      bufferingDuration = (time - bufferingStartTime) / 1000;
      setBuffering(false);
    }

    logBuffering(second, bufferingDuration);
  };

  const onProgress = () => {
    //Get the delta time
    const currentSecond = getVideoSecond();
    const deltaTime = Math.abs(currentSecond - lastSecond);
    setLastSecond(currentSecond);
    //Check the start
    if (currentSecond < 0) {
      dispatch(seekTo(0));
      return;
    }
    //Check the end
    if (currentSecond > video.duration && isPlaying) {
      dispatch(seekTo(video.duration));
      //Prevent the YouTube jump bug
      if (deltaTime < MAX_ABS_DELTA_TIME) {
        logEndEvent();
      }
      dispatch(seekTo(0));
      return;
    }
    //Else update the slider value
    if (!isNaN(currentSecond)) {
      //Set the slider value
      dispatch(setSliderValue(currentSecond));
      if (deltaTime < MAX_ABS_DELTA_TIME) {
        //Search for question between the last second and the current second
        const questionsToAsk = findQuestionsToAsk(questions, lastSecond, currentSecond);
        if (questionsToAsk.length > 0) dispatch(pushQuestions(questionsToAsk));
      }
    }
  };

  const toggleFullScreen = useCallback(() => {
    if (screenfull.isEnabled) {
      if (screenfull.isFullscreen) {
        screenfull.exit();
        dispatch(setViewMode('default'));
      } else {
        if (screenfullRef.current) screenfull.request(screenfullRef.current);
        dispatch(setViewMode('fullscreen'));
      }
    }
  }, [dispatch, screenfullRef]);

  const onMouseEnter = () => {
    setMouseIn(true);
  };

  const onMouseLeave = () => {
    setMouseIn(false);
  };

  const onClickPlayer: React.MouseEventHandler = (e) => {
    if (!isPlaying) {
      dispatch(setIsPlaying(true));
    }
  };

  return {
    onReady,
    onStart,
    onPlay,
    onPause,
    onProgress,
    onBuffer,
    onBufferEnd,
    onPlaybackRateChange,
    onMouseEnter,
    onMouseLeave,
    onClickPlayer,
    toggleFullScreen,
    displayControls,
    videoStarted,
    volume,
    playbackRate,
    isPlaying,
    config,
  };
};

export default usePlayer;
