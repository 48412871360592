import {selectIsUserLogged} from '../../store/auth/auth.slice';
import {useSelector} from 'react-redux';
import {Navigate, Outlet, useLocation} from 'react-router-dom';

const ProtectedRoute = () => {
  const isUserLogged = useSelector(selectIsUserLogged);
  const location = useLocation();

  if (isUserLogged) return <Outlet />;
  else return <Navigate to='/' state={{from: location.pathname}} />;
};

export default ProtectedRoute;
