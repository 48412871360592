import {Modal} from 'react-bootstrap';
import * as Styles from './styles';
import VideoContainer from '../video-container/VideoContainer';
import IntervalVideoPlayer from '../interval-video-player/IntervalVideoPlayer';

type Props = {
  show: boolean;
  onHide: () => void;
  start: number;
  end: number;
  videoId: string;
};

const VideoModal = (props: Props) => {
  const {show, onHide, start, end, videoId} = props;

  return (
    <Styles.VideoModal show={show} onHide={onHide} size='xl' centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <VideoContainer>
          <IntervalVideoPlayer end={end} start={start} videoId={videoId} />
        </VideoContainer>
      </Modal.Body>
    </Styles.VideoModal>
  );
};

export default VideoModal;
