import {apiSlice} from '../api/api.slice';
import {LoginRequest, LoginResponse, LogoutResponse} from './auth.api-types';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //Login with email or username
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: ({emailOrUsername, password, prolificId, videoId}) => ({
        url: 'auth/login',
        method: 'POST',
        //Send prolificId only if it is provided
        body: {emailOrUsername, password, prolificId, videoId},
      }),
      invalidatesTags: ['StudentVideos', 'CreatorVideos'],
    }),
    logout: builder.mutation<LogoutResponse, void>({
      query: () => ({
        url: 'auth/logout',
        method: 'POST',
      }),
    }),
  }),
});

export const {useLoginMutation, useLogoutMutation} = authApiSlice;
