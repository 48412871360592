import {faRobot, faTrash} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {Alert, Button, Col, Container, Row, Table} from 'react-bootstrap';
import {toHHMMSS} from '../../../../utils/time';
import {useOutletContext} from 'react-router-dom';
import toast from 'react-hot-toast';
import {getMessageFromError} from '../../../../utils/errors';
import useFetch from './hooks/useFetch';
import Loading from '../../../../components/loading/Loading';
import {useDeleteAllTopicsForVideoMutation, useGenerateTopicsMutation} from '../../../../store/topics/topics.api';

const Topics = () => {
  const {video} = useOutletContext();

  const {topics, isError, isFetching, errorMessage, isSuccess} = useFetch(video.id);

  const [generateTopics, {isLoading: isLoading1}] = useGenerateTopicsMutation();
  const [deleteTopics, {isLoading: isLoading2}] = useDeleteAllTopicsForVideoMutation();
  const isLoading = isLoading1 || isLoading2 || isFetching;

  const canGenerateTopics = topics.length === 0 && !isLoading;
  const canDeleteTopics = topics.length > 0 && !isLoading;

  const onClickGenerateTopics = () => {
    if (canGenerateTopics)
      toast.promise(generateTopics(video.id).unwrap(), {
        loading: 'Generating topics...',
        success: 'Topics generated successfully',
        error: (err) => `Failed to generate topics. ${getMessageFromError(err)}`,
      });
  };

  const onClickDeleteTopics = () => {
    if (canDeleteTopics)
      toast.promise(deleteTopics(video.id).unwrap(), {
        loading: 'Deleting topics...',
        success: 'Topics deleted successfully',
        error: (err) => `Failed to delete topics. ${getMessageFromError(err)}`,
      });
  };

  return (
    <Container className='py-4'>
      <Row>
        <Col>
          <h2>Actions</h2>
          <div className='d-flex flex-row gap-2 justify-content-center align-items-center'>
            <Button variant='success' disabled={!canGenerateTopics} onClick={onClickGenerateTopics}>
              Generate topics with AI
              <FontAwesomeIcon icon={faRobot} className='ms-1' />
            </Button>
            <Button variant='danger' disabled={!canDeleteTopics} onClick={onClickDeleteTopics}>
              Delete all topics
              <FontAwesomeIcon icon={faTrash} className='ms-1' />
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className='mb-4'>Topics</h2>
          {isLoading ? (
            <Loading page={false} />
          ) : isError ? (
            <Alert variant='danger'>{errorMessage}</Alert>
          ) : !topics.length ? (
            <p className='text-muted fst-italic'>No topics found</p>
          ) : (
            isSuccess && (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Name</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {topics.map((topic, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{toHHMMSS(topic.startSecond)}</td>
                      <td>{toHHMMSS(topic.endSecond)}</td>
                      <td>{topic.name}</td>
                      <td>{topic.description}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Topics;
