import {BaseQueryApi, BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {resetCredentials, setCredentials} from '../auth/auth.slice';
import {RootState} from '..';
import {toastError} from '../../utils/toast';
import {User} from '../../types/dtos';

//Get base url from the environment variables
const baseUrl = process.env.REACT_APP_SERVER_URL;

const baseQuery = fetchBaseQuery({
  baseUrl,
  credentials: 'include',
  prepareHeaders: (headers, {getState}) => {
    const accessToken = (getState() as RootState).auth.accessToken;
    if (accessToken) headers.set('authorization', `Bearer ${accessToken}`);
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args: any, api: BaseQueryApi, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);

  const status = result?.error?.status;

  //If status is 403, the token is expired
  if (status === 403) {
    //Send refresh token to get the new access token
    const refreshResult = await baseQuery('/auth/refresh', api, extraOptions);

    const data = refreshResult.data as {accessToken: string};
    if (data) {
      const user = (api.getState() as RootState).auth.user as User;

      const accessToken = data.accessToken;
      //store the new token
      api.dispatch(setCredentials({user, accessToken}));
      //retry the original request
      result = await baseQuery(args, api, extraOptions);
    } else {
      //If refresh token is expired, logout the user
      api.dispatch(resetCredentials());
      //Toast session expired
      toastError('Session expired. Please login again.');
    }
  } else if (status === 401) {
    const data = result.error?.data as {code: string};
    const code = data.code;
    if (code === 'ACCOUNT_DISCONNECTED') {
      api.dispatch(resetCredentials());
      toastError('Your account has been disconnected. Please login again.');
    }
  }

  return result;
};

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Annotations', 'CreatorVideos', 'InVideoQuestions', 'StudentVideos', 'Transcript', 'Thoughts', 'TopicQuestions', 'ThoughtQuestions', 'Video', 'VideoTopics'],
  endpoints: (builder) => ({}),
});
