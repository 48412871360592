import {useEffect, useState} from 'react';
import useError from '../../../../../hooks/useError';
import {useGetVideoThoughtsQuery} from '../../../../../store/thoughts/thoughts.api';
import {Thought} from '../../../../../types/dtos';

const useFetch = (videoId: string) => {
  const {data, isFetching, isError, error, isSuccess} = useGetVideoThoughtsQuery(videoId);

  const [thoughts, setThoughts] = useState<Thought[]>([]);

  const errorMessage = useError({isError, error});

  useEffect(() => {
    if (isSuccess) setThoughts(data);
  }, [data, isSuccess]);

  return {thoughts, isFetching, isError, isSuccess, errorMessage};
};

export default useFetch;
