import {ThoughtQuestion} from '../../types/dtos';
import {apiSlice} from '../api/api.slice';
import {GetQuestionAboutAnnotationRequest} from './personalized-quiz.api-types';

const personalizedQuizApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUnwatchedQuestion: builder.query<ThoughtQuestion | null, string>({
      query: (videoId) => `personalized-quiz/unwatched/${videoId}`,
    }),
    getWatchedQuestion: builder.query<ThoughtQuestion | null, string>({
      query: (videoId) => `personalized-quiz/watched/${videoId}`,
    }),
    getQuestionAboutAnnotation: builder.query<ThoughtQuestion | null, GetQuestionAboutAnnotationRequest>({
      query: ({videoId, annotationType}) => `personalized-quiz/annotation/${annotationType}/${videoId}`,
    }),
  }),
});

export const {useLazyGetUnwatchedQuestionQuery, useLazyGetWatchedQuestionQuery, useLazyGetQuestionAboutAnnotationQuery} = personalizedQuizApiSlice;
