import {useMemo, useState} from 'react';
import * as Styles from './styles';
import {Alert, Button, Container} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faThumbsDown, faThumbsUp} from '@fortawesome/free-solid-svg-icons';
import BadRatingModal from './components/BadRatingModal';
import {useRateThoughtQuestionMutation} from '../../../../store/questions/questions.api';
import toast from 'react-hot-toast';
import Loading from '../../../../components/loading/Loading';
import {ThoughtQuestion, ThoughtQuestionAnswer} from '../../../../types/dtos';

interface QuestionProps {
  question: ThoughtQuestion;
  resetQuestion: () => void;
}

const Question = ({question, resetQuestion}: QuestionProps) => {
  const {text} = question;
  const [selectedAnswer, setSelectedAnswer] = useState<ThoughtQuestionAnswer | undefined>();
  const [hasAnswered, setHasAnswered] = useState<boolean>(false);
  const [showRatingModal, setShowRatingModal] = useState<boolean>(false);

  const [rateQuestion, {isLoading}] = useRateThoughtQuestionMutation();

  //Shuffle answers
  const answers = useMemo(() => {
    const answers = [...(question.answers as ThoughtQuestionAnswer[])];
    return answers.sort(() => Math.random() - 0.5);
  }, [question.answers]);

  const getClassName = (answer: ThoughtQuestionAnswer) => {
    if (!selectedAnswer) return '';
    if (hasAnswered) {
      if (answer.isCorrect) return 'selected-correct';
      if (answer.id === selectedAnswer.id && !answer.isCorrect) return 'selected-wrong';
    } else {
      if (answer.id === selectedAnswer.id) return 'selected';
    }
  };

  const onSelectAnswer = () => {
    if (!hasAnswered) setHasAnswered(true);
  };

  const onRateQuestion = (isPositive: boolean) => {
    if (isPositive) {
      rateQuestion({questionId: question.id, isPositive})
        .unwrap()
        .then(() => {
          toast.success('Thank you for your feedback!');
          resetQuestion();
        })
        .catch(() => {
          toast.error('An error occurred while sending your feedback');
        });
    } else {
      setShowRatingModal(true);
    }
  };

  if (isLoading) return <Loading page={true} />;
  return (
    <Container>
      <Styles.Question>{text}</Styles.Question>
      {answers.map((answer) => (
        <Styles.Answer disabled={hasAnswered} className={getClassName(answer)} key={answer.id} onClick={() => setSelectedAnswer(answer)}>
          {answer.text}
        </Styles.Answer>
      ))}
      {/* Confirm button */}
      {selectedAnswer && !hasAnswered && (
        <Button variant='warning' onClick={onSelectAnswer} className='w-100' size='lg'>
          Confirm
        </Button>
      )}
      {/* Feedback */}
      {hasAnswered && selectedAnswer && (
        <>
          <Alert variant={selectedAnswer.isCorrect ? 'success' : 'danger'}>{selectedAnswer.feedback}</Alert>
          {/* Feedback button */}
          <div className='d-flex flex-row justify-content-end align-items-center gap-2'>
            <span>Please, rate this question:</span>
            <Button variant='danger' size='lg' onClick={() => onRateQuestion(false)}>
              <FontAwesomeIcon icon={faThumbsDown} />
            </Button>
            <Button variant='success' size='lg' onClick={() => onRateQuestion(true)}>
              <FontAwesomeIcon icon={faThumbsUp} />
            </Button>
          </div>
        </>
      )}
      {/* Modal for the feedback */}
      <BadRatingModal show={showRatingModal} question={question} onHide={() => setShowRatingModal(false)} resetQuestion={resetQuestion} />
    </Container>
  );
};

export default Question;
