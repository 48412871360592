import {Topic} from '../../types/dtos';
import {MessageResponse} from '../../types/response';
import {apiSlice} from '../api/api.slice';

const topicsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //Generate topics
    generateTopics: builder.mutation<Topic[], string>({
      query: (videoId) => ({
        url: `topics/generate/${videoId}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, videoId) => [{type: 'VideoTopics', id: videoId}],
    }),
    //Get video topics
    getVideoTopics: builder.query<Topic[], string>({
      query: (videoId) => `topics/${videoId}`,
      providesTags: (result, error, videoId) => [{type: 'VideoTopics', id: videoId}],
    }),
    //Delete all topics for video
    deleteAllTopicsForVideo: builder.mutation<MessageResponse, string>({
      query: (videoId) => ({
        url: `topics/delete-all/${videoId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, videoId) => [{type: 'VideoTopics', id: videoId}],
    }),
  }),
});

export const {useGenerateTopicsMutation, useGetVideoTopicsQuery, useDeleteAllTopicsForVideoMutation} = topicsApiSlice;
