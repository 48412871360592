import styled from 'styled-components';

const Styles = {
  Footer: styled.footer`
    background-color: ${(p) => p.theme.secondary};
    color: ${(p) => p.theme.textPrimary};
    padding: 1rem;
    text-align: center;
    height: ${(p) => p.theme.footerMinHeight};
  `,
};

export default Styles;
