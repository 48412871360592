import {useGetVideoByIdQuery} from '../../../store/videos/videos.api';
import {useEffect, useState} from 'react';
import {toastError} from '../../../utils/toast';
import {getMessageFromError} from '../../../utils/errors';
import {Video} from '../../../types/dtos';

const useFetch = (videoId: string) => {
  const {data, isLoading, isError, error, isSuccess} = useGetVideoByIdQuery(videoId);
  const [video, setVideo] = useState<Video | undefined>();

  useEffect(() => {
    if (isSuccess) setVideo(data);
    else if (isError) toastError(getMessageFromError(error));
  }, [data, isSuccess, isError, error]);

  return {video, isLoading, isError, isSuccess};
};

export default useFetch;
