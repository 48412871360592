import {useState} from 'react';
import {Button, Container, Form} from 'react-bootstrap';
import InputContainer from '../../../../components/form-control/InputContainer';
import {useNavigate} from 'react-router-dom';
import {useDeleteVideoMutation, useEditVideoMutation} from '../../../../store/videos/videos.api';
import toast from 'react-hot-toast';
import {getMessageFromError} from '../../../../utils/errors';
import Loading from '../../../../components/loading/Loading';
import {useVideo} from '../../TeacherVideo';
import {QuestionSet} from '../../../../types/dtos';

const Edit = () => {
  const {video} = useVideo();

  const navigate = useNavigate();

  const [name, setName] = useState<string>(video.name);
  const [questionSet, setQuestionSet] = useState<QuestionSet>(video.questionSet as QuestionSet);
  const canSubmit = name.trim().length > 0;

  const [deleteVideo, {isLoading}] = useDeleteVideoMutation();
  const [editName] = useEditVideoMutation();

  const onSubmitForm: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (canSubmit) {
      console.log({id: video.id, name, questionSet});
      toast.promise(editName({id: video.id, name, questionSet}).unwrap(), {
        loading: 'Saving...',
        success: 'Video updated successfully',
        error: (err) => `Cannot update video. ${getMessageFromError(err)}`,
      });
    }
  };

  const onClickDelete = () => {
    deleteVideo(video.id)
      .unwrap()
      .then(() => {
        toast.success('Video deleted');
        navigate('/creator');
      })
      .catch((err) => {
        toast.error(`Cannot delete video. Error ${getMessageFromError(err)}`);
      });
  };

  const changeOrderHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as QuestionSet;
    setQuestionSet(value);
  };

  if (isLoading) return <Loading />;
  return (
    <Container className='py-4'>
      <h2 className='mb-4'>Edit video</h2>
      <Form className='mb-4' onSubmit={onSubmitForm}>
        <InputContainer formGroupClassName='mb-3' label='Name' id='name'>
          <Form.Control type='text' placeholder='Enter video name' value={name} onChange={(e) => setName(e.target.value)} />
        </InputContainer>
        <div className='d-flex flex-column gap-1 mb-3'>
          <label>Choose which question should be asked to the students:</label>
          <select className='form-select' value={questionSet} onChange={changeOrderHandler}>
            <option value='none'>None</option>
            <option value='trasncript'>Questions generated from transcript</option>
            <option value='topic'>Questions generated from topics</option>
            <option value='concept'>Questions generated from concepts</option>
          </select>
        </div>
        <Button disabled={!canSubmit} variant='primary' type='submit'>
          Save
        </Button>
      </Form>
      <h2 className='mb-3'>Delete video</h2>
      <Button variant='danger' onClick={onClickDelete}>
        Delete video
      </Button>
    </Container>
  );
};

export default Edit;
