import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {apiSlice} from './api/api.slice';
import authReducer from './auth/auth.slice';
import playerReducer from './player/player.slice';
import socketReducer from './socket/socket.slice';
import socketMiddleware from './socket/socket.middleware';

//Persist the auth slice
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

//Combine all reducers
const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  player: playerReducer,
  socket: socketReducer,
});

//Create the persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

//Create the store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([apiSlice.middleware, socketMiddleware]),
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;

export const persistor = persistStore(store);
