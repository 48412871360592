import {useEffect} from 'react';

import {useDispatch} from 'react-redux';
import {initPlayerState} from '../../../store/player/player.slice';
import {toastError} from '../../../utils/toast';
import {getMessageFromError} from '../../../utils/errors';
import {useGetVideoByIdQuery} from '../../../store/videos/videos.api';

const useFetch = (videoId: string) => {
  const {data: video, isLoading, isError, error, isSuccess} = useGetVideoByIdQuery(videoId);
  const dispatch = useDispatch();

  //Save the video to the store
  useEffect(() => {
    if (isSuccess) dispatch(initPlayerState(video));
    else if (isError && error) toastError(getMessageFromError(error));
  }, [video, dispatch, isSuccess, isError, error]);

  return {video, isLoading, isError, isSuccess};
};

export default useFetch;
