import styled from 'styled-components';

export const SearchBar = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0;

  .search-input {
    border-radius: 0.375rem 0 0 0.375rem;
    padding-left: 10px;
    height: 2.3rem;
    border: 1px solid ${(props) => props.theme.secondary};
    border-right: none;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  .search-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 2.3rem;
    background-color: ${(p) => p.theme.accent};
    border: 1px solid ${(props) => props.theme.primary};
    border-left: none;
    height: 2.3rem;
    border-radius: 0 1rem 1rem 0;
    color: ${(p) => p.theme.textPrimary};
    cursor: pointer;
  }
`;
