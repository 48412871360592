import ReactSlider from 'react-slider';
import styled from 'styled-components';

const Styles = {
  Slider: styled(ReactSlider)`
    width: 100%;
    height: 1.5rem;
  `,

  Thumb: styled.div`
    height: 1.5rem;
    font-size: 0.7rem;
    line-height: 1.5rem;
    text-align: center;
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    background-color: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.textPrimary};
  `,

  Track: styled.div<{index: number}>`
    top: 0;
    bottom: 0;
    background: ${(props) => (props.index === 1 ? props.theme.secondary : '#ddd')};
  `,

  Label: styled.div`
    height: 1.5rem;
    font-size: 0.7rem;
    line-height: 1.5rem;
    text-align: center;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    background-color: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.textPrimary};
  `,
};
export default Styles;
