import {TranscriptSegment, Video} from '../../types/dtos';
import {MessageResponse} from '../../types/response';
import {apiSlice} from '../api/api.slice';
import {CreateVideoRequest, EditVideoRequest, GenerateTranscriptWithWhisperRequest, GetCreatorVideosRequest, GetCreatorVideosResponse, GetStudentVideosRequest, GetStudentVideosResponse} from './videos.api-types';

const videosApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //Add video to the student library
    addVideo: builder.mutation<Video, string>({
      query: (videoCode) => ({
        url: `videos/add/${videoCode}`,
        method: 'PUT',
      }),
      invalidatesTags: ['StudentVideos'],
    }),
    //Create video
    createVideo: builder.mutation<Video, CreateVideoRequest>({
      query: ({name, idYoutube, startSecond, endSecond}) => ({
        url: 'videos/create',
        method: 'PUT',
        body: {name, idYoutube, startSecond, endSecond},
      }),
      invalidatesTags: ['CreatorVideos'],
    }),
    //Generate transcript without Whisper
    generateTranscriptWithoutWhisper: builder.mutation<TranscriptSegment[], string>({
      query: (videoId) => ({
        url: `videos/transcript/create/${videoId}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, videoId) => [{type: 'Transcript', id: videoId}],
    }),
    //Generate transcript with Whisper
    generateTranscriptWithWhisper: builder.mutation<TranscriptSegment[], GenerateTranscriptWithWhisperRequest>({
      query: ({videoId, timestamp_granularities}) => ({
        url: `videos/transcript-whisper/create/${videoId}/${timestamp_granularities}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, {videoId}) => [{type: 'Transcript', id: videoId}],
    }),
    //Get student's video library
    getStudentVideos: builder.query<GetStudentVideosResponse, GetStudentVideosRequest>({
      query: ({skip, take, orderBy, orderDirection, search}) => `videos/student-videos/${skip}/${take}/${orderBy}/${orderDirection}/${search || ''}`,
      providesTags: ['StudentVideos'],
    }),
    //Get creator's video
    getCreatorVideos: builder.query<GetCreatorVideosResponse, GetCreatorVideosRequest>({
      query: ({skip, take, orderBy, orderDirection, search}) => `videos/creator-videos/${skip}/${take}/${orderBy}/${orderDirection}/${search || ''}`,
      providesTags: ['CreatorVideos'],
    }),
    //Get video by id
    getVideoById: builder.query<Video, string>({
      query: (id) => `videos/video/${id}`,
      providesTags: (result) => [{type: 'Video', id: result?.id}],
    }),
    //Get video transcript
    getVideoTranscript: builder.query<TranscriptSegment[], string>({
      query: (id) => `videos/transcript/${id}`,
      providesTags: (result, error, id) => [{type: 'Transcript', id: id}],
    }),
    //Edit video name
    editVideo: builder.mutation<Video, EditVideoRequest>({
      query: ({id, name, questionSet}) => ({
        url: `videos/edit/${id}`,
        method: 'PATCH',
        body: {name, questionSet},
      }),
      invalidatesTags: (result, error, args) => [{type: 'Video', id: args.id}, 'CreatorVideos'],
    }),
    //Join video transcript
    joinTranscript: builder.mutation<MessageResponse, string>({
      query: (videoId) => ({
        url: `videos/transcript/join/${videoId}`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, videoId) => [{type: 'Transcript', id: videoId}],
    }),
    //Remove video from the student library
    removeVideo: builder.mutation<MessageResponse, string>({
      query: (id) => ({
        url: `videos/student/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['StudentVideos'],
    }),
    //Delete video
    deleteVideo: builder.mutation<MessageResponse, string>({
      query: (id) => ({
        url: `videos/creator/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => ['CreatorVideos'],
    }),
    //Delete transcript
    deleteTranscript: builder.mutation<MessageResponse, string>({
      query: (id) => ({
        url: `videos/transcript/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{type: 'Transcript', id: id}],
    }),
  }),
});

export const {useAddVideoMutation, useCreateVideoMutation, useGenerateTranscriptWithoutWhisperMutation, useGenerateTranscriptWithWhisperMutation, useGetStudentVideosQuery, useGetCreatorVideosQuery, useGetVideoByIdQuery, useGetVideoTranscriptQuery, useEditVideoMutation, useJoinTranscriptMutation, useRemoveVideoMutation, useDeleteVideoMutation, useDeleteTranscriptMutation} = videosApiSlice;
