import {faCheckCircle, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

type Props = {
  text: string;
  isCorrect: boolean;
  feedback: string;
};

const Answer = ({text, feedback, isCorrect}: Props) => {
  return (
    <div className='mb-1'>
      <div className='d-flex flex-row align-items-center'>
        <div className='me-2 fs-5'>{isCorrect ? <FontAwesomeIcon icon={faCheckCircle} style={{color: 'green'}} /> : <FontAwesomeIcon icon={faTimesCircle} style={{color: 'red'}} />}</div>
        <div>{text}</div>
      </div>
      <div>
        <span className={`fw-bold ${isCorrect ? 'text-success' : 'text-danger'}`}>Feedback: </span>
        <span>{feedback}</span>
      </div>
    </div>
  );
};

export default Answer;
