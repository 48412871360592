import {useParams, Navigate, Outlet, useOutletContext} from 'react-router-dom';
import PageSkeleton from '../../components/page-skeleton/PageSkeleton';
import Loading from '../../components/loading/Loading';
import {Video} from '../../types/dtos';
import PageHeader from '../../components/page-header/PageHeader';
import useFetch from './hooks/useFetch';

type ContextType = {
  video: Video;
};

const StudentVideo = () => {
  const {videoId = ''} = useParams();

  const {video, isLoading, isError, isSuccess} = useFetch(videoId);

  if (isError) return <Navigate replace to='/student' />;
  if (isLoading) return <Loading />;
  return (
    <>
      {isSuccess && video && (
        <PageSkeleton>
          <PageHeader>
            <PageHeader.Title>{video.name}</PageHeader.Title>
          </PageHeader>
          {/* Pass the video as outlet context */}
          <Outlet context={{video} satisfies ContextType} />
        </PageSkeleton>
      )}
    </>
  );
};

export const useVideo = () => {
  return useOutletContext<ContextType>();
};

export default StudentVideo;
