import {Modal} from 'react-bootstrap';

interface Props {
  text: string;
  show: boolean;
  onHide: () => void;
}

const TextModal = ({text, show, onHide}: Props) => {
  return (
    <Modal show={show} onHide={onHide} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Transcript text</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{text}</p>
      </Modal.Body>
    </Modal>
  );
};

export default TextModal;
