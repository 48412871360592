import React, {useState, useRef, useEffect, Dispatch, SetStateAction, useContext, useCallback} from 'react';
import RangeSlider from 'react-bootstrap-range-slider';
import ReactPlayer from 'react-player';
import * as Styles from './styles';
import {toHHMMSS} from '../../utils/time';
import {ThemeContext} from '../../theme/ThemeContext';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCompress, faExpand, faPause, faPlay, faVolumeDown, faVolumeMute, faVolumeOff, faVolumeUp} from '@fortawesome/free-solid-svg-icons';
import screenfull from 'screenfull';

interface IntervalVideoPlayerProps {
  start: number;
  end: number;
  videoId: string;
  setDuration?: Dispatch<SetStateAction<number>>;
}

const IntervalVideoPlayer = ({start, end, videoId, setDuration}: IntervalVideoPlayerProps) => {
  //Player config
  const config = {
    youtube: {
      height: '100%',
      playerVars: {
        playsinline: 0,
        modestbranding: 1,
        showinfo: 0,
        controls: 0,
        rel: 0,
        fs: 0,
      },
    },
  };

  //Component state
  const {theme} = useContext(ThemeContext);
  const [playing, setPlaying] = useState<boolean>(true);
  const [volume, setVolume] = useState<number>(50);
  const [lastActiveVolume, setLastActiveVolume] = useState<number>(50);
  const [videoTime, setVideoTime] = useState<number>(start); //Current video time, referring to the entire YouTube video
  const [mouseIn, setMouseIn] = useState<boolean>(false);
  const [videoStarted, setVideoStarted] = useState<boolean>(false);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const playerRef = useRef<ReactPlayer | null>(null);
  const screenfullRef = useRef<HTMLDivElement>(null);
  const volumeSliderColor = theme.accent;
  const displayControls = videoStarted && (mouseIn || !playing);

  const getCurrentVideoSeconds = () => {
    const second = playerRef.current?.getCurrentTime();
    return second ? second : -1;
  };

  const seekTo = (seconds: number) => {
    if (playerRef.current) playerRef.current.seekTo(seconds);
  };

  const onProgress = () => {
    const currentTime = getCurrentVideoSeconds();
    if ((currentTime < start || currentTime > end) && playerRef.current) {
      playerRef.current.seekTo(start);
      setPlaying(false);
      setVideoTime(start);
      seekTo(start);
    } else {
      setVideoTime(currentTime);
    }
  };

  const onReady = () => {
    //Play the video
    setPlaying(true);
  };

  const changeLastActiveVolume = (e: React.ChangeEvent, value: number) => {
    //Volume represents the last volume value different than 0
    if (value !== 0) setLastActiveVolume(value);
  };

  const toggleMute = () => {
    //If is mute, return to the last value different than 0
    if (volume === 0) {
      setVolume(lastActiveVolume);
    } else {
      //If is not muted, mute, but don't edit the last volume value different than 0
      setVolume(0);
    }
  };

  //Handlers for the time slider
  const moveTimeSliderHandler = (e: React.ChangeEvent, value: number) => {
    //Stop the video when the user is moving the slider
    setPlaying(false);
    //Update the video time
    setVideoTime(value);
  };

  const releaseTimeSliderHandler = (e: React.ChangeEvent, value: number) => {
    setPlaying(false);
    //Update the time
    setVideoTime(value);
    //Seek to the slider value
    seekTo(value);
  };

  const onDuration = (duration: number) => {
    setDuration && setDuration(duration);
  };

  const onStart = () => {
    setVideoStarted(true);
  };

  const toggleFullScreen = useCallback(() => {
    if (screenfull.isEnabled) {
      if (screenfull.isFullscreen) {
        screenfull.exit();
        setIsFullScreen(false);
      } else {
        if (screenfullRef.current) screenfull.request(screenfullRef.current);
        setIsFullScreen(true);
      }
    }
  }, [screenfullRef]);

  //When start second change seek back to the start second
  useEffect(() => {
    seekTo(start);
  }, [start]);

  return (
    <div ref={screenfullRef} className='h-100' onMouseEnter={() => setMouseIn(true)} onMouseLeave={() => setMouseIn(false)}>
      <ReactPlayer ref={playerRef} className='youtube-video w-100 h-100' url={`https://www.youtube.com/watch?v=${videoId}`} playing={playing} controls={false} volume={volume / 100} progressInterval={200} config={config} onReady={onReady} onDuration={onDuration} onPause={() => setPlaying(false)} onPlay={() => setPlaying(true)} onProgress={onProgress} onStart={onStart} />
      {displayControls && (
        <Styles.VideoControlsContainer>
          <div className='timeline'>
            <RangeSlider min={start} max={end} tooltip='off' value={videoTime} onChange={moveTimeSliderHandler} onAfterChange={releaseTimeSliderHandler} size='lg' />
          </div>
          <Styles.Controls>
            <div className='start'>
              {playing ? (
                /* Pause icon */
                <FontAwesomeIcon icon={faPause} onClick={() => setPlaying(false)} />
              ) : (
                /* Play icon */
                <FontAwesomeIcon icon={faPlay} onClick={() => setPlaying(true)} />
              )}
              <Styles.VolumeContainer>
                <div onClick={toggleMute}>
                  {volume === 0 ? (
                    /* Volume 0 icon */
                    <FontAwesomeIcon icon={faVolumeMute} />
                  ) : volume > 0 && volume < 33 ? (
                    /* Volume off icon */
                    <FontAwesomeIcon icon={faVolumeOff} />
                  ) : volume >= 33 && volume < 66 ? (
                    /* Volume low icon */
                    <FontAwesomeIcon icon={faVolumeDown} />
                  ) : (
                    /* Volume high icon */
                    <FontAwesomeIcon icon={faVolumeUp} />
                  )}
                </div>
                <RangeSlider className='volume-slider' color={volumeSliderColor} value={volume} tooltip='off' onChange={(e) => setVolume(parseInt(e.target.value))} onAfterChange={changeLastActiveVolume} min={0} max={100} />
              </Styles.VolumeContainer>
              <Styles.DurationContainer>
                {toHHMMSS(videoTime)} / {toHHMMSS(end)}
              </Styles.DurationContainer>
            </div>
            <FontAwesomeIcon icon={isFullScreen ? faCompress : faExpand} className='ms-auto action-btn' onClick={toggleFullScreen} />
          </Styles.Controls>
        </Styles.VideoControlsContainer>
      )}
    </div>
  );
};

export default IntervalVideoPlayer;
