import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import ReactSlider from 'react-slider';
import * as Styles from './styles';
import {selectPlayerInfo} from '../../../../../store/player/player.slice';
import {Question} from '../../../../../types/question';

interface TimeSliderProps {
  questions: Question[];
}

const TimeSlider = ({questions}: TimeSliderProps) => {
  const {video, sliderValue} = useSelector(selectPlayerInfo);
  const marksPosition = useMemo(() => [...new Set(questions.map((q) => q.popupSecond))], [questions]);

  return (
    <Styles.TimeSliderContainer>
      <ReactSlider disabled min={0} max={video?.duration} defaultValue={0} value={sliderValue} className='horizontal-slider' thumbClassName='time-thumb' trackClassName='time-track' thumbActiveClassName='time-thumb-active' marks={marksPosition} markClassName='time-mark' />
    </Styles.TimeSliderContainer>
  );
};

export default TimeSlider;
