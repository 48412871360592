import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {disconnectSocket, selectIsConnectionFailed, selectIsReconnectionFailed, startConnecting} from '../../../../../store/socket/socket.slice';
import toast from 'react-hot-toast';
import {selectAccessToken} from '../../../../../store/auth/auth.slice';

interface UseSocketProps {
  videoId: string;
  canConnect: boolean;
}

const useSocket = ({videoId, canConnect}: UseSocketProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isConnectionFailed = useSelector(selectIsConnectionFailed);
  const isReconnectionFailed = useSelector(selectIsReconnectionFailed);
  const accessToken = useSelector(selectAccessToken) as string;

  //Start the connection the connection when the component is mounted
  const connectSocket = useCallback(() => {
    dispatch(startConnecting({videoId, accessToken}));
    console.log('Connecting socket...');
  }, [dispatch, videoId, accessToken]);

  useEffect(() => {
    if (canConnect) connectSocket();

    return () => {
      dispatch(disconnectSocket());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canConnect, videoId]);

  //If socket fail the connection
  useEffect(() => {
    if (isConnectionFailed || isReconnectionFailed) {
      //Reset the state before redirecting
      dispatch(disconnectSocket());
      navigate('/student'); //Redirect to home
      //If reconnecting fails, show error toast
      if (isReconnectionFailed) toast.error('Cannot connect to the video, please try again later.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnectionFailed, isReconnectionFailed]);

  return null;
};

export default useSocket;
