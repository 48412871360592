import {connectionEstablished, connectionFailed, disconnectSocket, reconnectionEstablished, reconnectionFailed, startConnecting, startReconnecting} from './socket.slice';
import {Socket, io} from 'socket.io-client';
import {toastError} from '../../utils/toast';
import {Middleware} from '@reduxjs/toolkit';

const socketMiddleware: Middleware = (store) => {
  let socket: Socket | null = null;

  return (next) => (action) => {
    if (startConnecting.match(action)) {
      //If socket is already connected, the disconnect it
      if (socket?.connected) socket.disconnect();

      //Get the server URL
      const serverURL = process.env.REACT_APP_SOCKET_URL as string;
      //Extract the payload
      const {videoId, accessToken} = action.payload;

      //Establish the connection
      socket = io(serverURL, {
        withCredentials: true,
        reconnectionAttempts: 3,
        auth: {
          accessToken,
        },
        query: {
          videoId,
        },
      });

      //On connection authorized dispatch the connectionEstablished action
      socket.on('connect', () => {
        store.dispatch(connectionEstablished({socket}));
      });

      //On error dispatch the disconnectSocket action, NOTE: I disconnect socket every time I have an error
      socket.on('error', (err) => {
        toastError('Sorry, an error occurred. Please try again.');
        console.error(err);
      });

      //Fired upon an attempt to reconnect.
      socket.io.on('reconnect_attempt', (attempt) => {
        store.dispatch(startReconnecting());
        console.log('reconnect_attempt', attempt);
      });

      //Fired when couldn't reconnect within reconnectionAttempts
      socket.io.on('reconnect_failed', () => {
        toastError('Disconnected from the server.');
        store.dispatch(reconnectionFailed());
        console.log('Connecion failed');
      });

      //Fired upon a successful reconnection.
      socket.io.on('reconnect', (attempt) => {
        store.dispatch(reconnectionEstablished());
        console.log('reconnected successfully at attempt: ', attempt);
      });

      //Fired upon a disconnection.
      socket.on('disconnect', (reason) => {
        console.log('disconnect', reason);
        //Understand if the disconnection is originated by the server, with socket.disconnect() or by the client
        if (reason === 'io server disconnect' && socket?.connected) {
          socket.disconnect();
          store.dispatch(connectionFailed());
        }
      });
    }

    //If action is disconnectSocket, disconnect the socket
    if (disconnectSocket.match(action)) {
      if (socket?.connected) socket.disconnect();
    }

    //Pass to the next middleware
    next(action);
  };
};

export default socketMiddleware;
