import {useState} from 'react';

interface PaginationProps {
  limit: number;
  itemCount: number;
}

const usePagination = ({limit, itemCount}: PaginationProps) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageCount = Math.ceil(itemCount / limit);
  const offset = (currentPage - 1) * limit;

  const onChangePage = (pageValue: number) => {
    if (pageValue >= 1 && pageValue <= pageCount) setCurrentPage(pageValue);
  };

  return {
    currentPage,
    pageCount,
    onChangePage,
    offset,
  };
};

export default usePagination;
