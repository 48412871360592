export type Theme = {
  primary: string;
  secondary: string;
  textPrimary: string;
  textSecondary: string;
  accent: string;
  primaryRGB: string;
  secondaryRGB: string;
  textPrimaryGB: string;
  textSecondaryRGB: string;
  accentRGB: string;
  navbarMinHeight: string;
  footerMinHeight: string;
};

const main: Theme = {
  primary: '#4f6373',
  secondary: '#728fa5',
  textPrimary: '#ffffff',
  textSecondary: '#c8c8c8',
  accent: '#5eb2f2',
  primaryRGB: '79, 103, 115',
  secondaryRGB: '114, 143, 165',
  textPrimaryGB: '255, 255, 255',
  textSecondaryRGB: '200, 200, 200',
  accentRGB: '94, 178, 242',
  navbarMinHeight: '3.5rem',
  footerMinHeight: '3rem',
};

export const ThemeList: Record<string, Theme> = {
  main,
};
