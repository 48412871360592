import {RingLoader} from 'react-spinners';
import * as Styles from './styles';
import {useContext} from 'react';
import {ThemeContext} from '../../theme/ThemeContext';

const Loading = ({page = true}) => {
  const {theme} = useContext(ThemeContext);

  const color = theme.accent || '#5eb2f2';
  const size = '10rem';

  if (page)
    return (
      <Styles.Loading>
        <RingLoader color={color} size={size} />
      </Styles.Loading>
    );
  else
    return (
      <div className='d-flex justify-content-center py-5'>
        <RingLoader color={color} size={size} />;
      </div>
    );
};

export default Loading;
