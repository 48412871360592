import PageSkeleton from '../../components/page-skeleton/PageSkeleton';
import PageHeader from '../../components/page-header/PageHeader';
import {Outlet} from 'react-router-dom';
import useMenu from '../../hooks/useMenu';

const StudentDashboard = () => {
  const linkList = [
    {
      name: 'My videos',
      path: '/student',
      pattern: '/student',
    },
    {
      name: 'Add video',
      path: '/student/new',
      pattern: '/student/new',
    },
  ];

  const {active, handleMenuClick} = useMenu({linkList});

  return (
    <PageSkeleton>
      <PageHeader>
        <PageHeader.Title>My Video Library</PageHeader.Title>
        <PageHeader.Subtitle>Dear student, here you can access your videos. Enjoy learning!</PageHeader.Subtitle>
        <PageHeader.Menu>
          {linkList.map((link, index) => (
            <PageHeader.MenuItem key={index} className={active === index ? 'active' : ''} onClick={() => handleMenuClick(index)}>
              {link.name}
            </PageHeader.MenuItem>
          ))}
        </PageHeader.Menu>
      </PageHeader>
      <Outlet />
    </PageSkeleton>
  );
};

export default StudentDashboard;
