import React from 'react';

interface OrderingProps {
  changeOrderHandler: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  orderValue: string;
  orderingList: {key: string; name: string}[];
}

const Ordering = ({changeOrderHandler, orderValue, orderingList}: OrderingProps) => {
  return (
    <div className='d-flex flex-column gap-1 flex-grow-1'>
      <label>Order by</label>
      <select className='form-select' value={orderValue} onChange={changeOrderHandler}>
        {orderingList.map((item) => (
          <option key={item.key} value={item.key}>
            {item.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Ordering;
