import {createGlobalStyle} from 'styled-components';

export default createGlobalStyle`
  html,
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    background: ${(p) => p.theme.textPrimary};
  }

  * {
    box-sizing: border-box;
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(p) => p.theme.textPrimary};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.accent};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.primary};
  }

  /* Set Monserrat font already imported in all the headings */
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
  }

  /* Set default font family */
  body {
    font-family: 'Roboto', sans-serif;
  }
  
  .page-container {
    min-height: calc(100vh - ${(p) => p.theme.navbarMinHeight} - ${(p) => p.theme.footerMinHeight});
  }

  .link-no-style {
    text-decoration: none;
    color: inherit;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
  }
`;
