import {useVideo} from '../../StudentVideo';
import {Button, Col, Container, Row} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {selectProlificId} from '../../../../store/auth/auth.slice';
import React, {useEffect, useState} from 'react';
import {RingLoader} from 'react-spinners';

const PreTest = () => {
  const {video} = useVideo();

  //Loading state
  const [isLoading, setIsLoading] = useState(true);

  const prolificId = useSelector(selectProlificId);
  const videoPageURL = `/student/watch/${video.id}`;
  const pretestURL = video.pretestURL ? video.pretestURL + '?PROLIFIC_PID=' + prolificId : undefined;
  const navigate = useNavigate();
  const iframeStyle = {
    width: '100%',
    height: '100vh',
  };

  // If the pretest URL is not available, redirect to the video page
  useEffect(() => {
    if (!pretestURL) navigate(videoPageURL, {replace: true});
  }, [pretestURL, navigate, videoPageURL]);

  const onLoadIframe = () => {
    console.log('Pre-test loaded');
    setIsLoading(false);
  };

  const onErrorIframe = (e: React.SyntheticEvent<HTMLIFrameElement, Event>) => {
    console.log(e);
    setIsLoading(false);
  };

  return (
    <Container className='py-4'>
      <Row>
        <Col>
          <h2>Pre-Test</h2>
          <p>Answer the following questions to the best of your knowledge. Each question needs to be answered. You will have to scroll down within the questionnaire to see all questions. Once you have answered all questions click on the blue arrow in the bottom right corner. Afterwards click on the green button to proceed to the next part.</p>
          <div className='mb-4' />
          {isLoading && <IFrameLoadingSpinner />}
          {pretestURL && (
            <>
              <iframe style={iframeStyle} id='pre-test-iframe' title='Pre-test' width='100%' height='100%' src={pretestURL} onLoad={onLoadIframe} onError={onErrorIframe} />
              <div className='d-flex justify-content-center'>
                <Link to={videoPageURL}>
                  <Button variant='success' size='lg'>
                    Click me when you finish the pre-test
                  </Button>
                </Link>
              </div>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

const IFrameLoadingSpinner = () => {
  return (
    <div className='d-flex flex-column align-items-center justify-content-center py-5'>
      <RingLoader color='#5eb2f2' size='10rem' />
      <p className='text-center fw-bold text-primary mt-3'>Loading pre-test...</p>
    </div>
  );
};

export default PreTest;
