import {useEffect, useState} from 'react';
import {toastError} from '../../../utils/toast';
import {getMessageFromError} from '../../../utils/errors';
import {useGetQuestionsToAskQuery} from '../../../store/questions/questions.api';
import {Video} from '../../../types/dtos';
import {Question} from '../../../types/question';

const useFetch = (video: Video) => {
  const {data, isLoading, isError, error, isSuccess} = useGetQuestionsToAskQuery(video.id);

  const [questions, setQuestions] = useState<Question[]>([]);

  useEffect(() => {
    if (isSuccess) {
      setQuestions(data);
    } else if (isError) toastError(getMessageFromError(error));
  }, [data, isSuccess, isError, error]);

  return {
    questions,
    isSuccess,
    isLoading,
    isError,
  };
};

export default useFetch;
