import {useEffect} from 'react';
import {useGetVideoByIdQuery} from '../../../store/videos/videos.api';
import useError from '../../../hooks/useError';
import {toastError} from '../../../utils/toast';

const useFetch = (videoId: string) => {
  const {data: video, isLoading, isError, error, isSuccess} = useGetVideoByIdQuery(videoId);

  const errorMessage = useError({isError, error});

  //Save the video to the store
  useEffect(() => {
    if (isError && errorMessage) toastError(errorMessage);
  }, [isError, errorMessage]);

  return {video, isLoading, isError, isSuccess};
};

export default useFetch;
