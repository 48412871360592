import styled from 'styled-components';

export const TimeBar = styled.div`
  position: relative;
  display: flex;
  height: 1rem;
  width: 100%;
  background-color: #ccc;
  border-radius: 0.5rem;
  overflow: hidden;
`;

export const TimeBarInterval = styled.div<{left: number; width: number}>`
  background-color: ${(props) => props.theme.accent};
  position: absolute;
  left: ${(props) => (props.left ? props.left : 0)}%;
  width: ${(props) => (props.width ? props.width : 0)}%;
  height: 100%;
`;
