import {Container, Row} from 'react-bootstrap';
import Loading from '../../../../components/loading/Loading';
import {useSelector} from 'react-redux';
import {selectIsConnected} from '../../../../store/socket/socket.slice';
import VideoContainer from '../../../../components/video-container/VideoContainer';
import useSocket from './hooks/useSocket';
import {useVideo} from '../../StudentVideo';
import UncontrollableVideoPlayer from '../../../../components/uncontrollable-video-player/UncontrollableVideoPlayer';

const VideoNoPersonalization = () => {
  const {video} = useVideo();

  const isConnected = useSelector(selectIsConnected);

  useSocket({videoId: video.id, canConnect: true});

  if (!isConnected) return <Loading />;
  return (
    <Container className='py-4'>
      <Row>
        <h5 className='mb-4'>Please watch the video below. The study will auto-advance once you have completed the video. You will be presented with multiple-choice questions at pauses in the video. Answer these questions to the best of your knowledge.</h5>
        <VideoContainer>
          <UncontrollableVideoPlayer video={video} />
        </VideoContainer>
      </Row>
    </Container>
  );
};

export default VideoNoPersonalization;
