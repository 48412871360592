import {getPassedTimeInString, toHHMMSS} from '../../../../../../utils/time';
import * as Styles from './styles';
import {Link} from 'react-router-dom';
import {Badge} from 'react-bootstrap';
import {Video} from '../../../../../../types/dtos';

interface VideoCardProps {
  video: Video;
}

const VideoCard = ({video}: VideoCardProps) => {
  const {id, name, idYoutube, startSecond, endSecond, createdAt, code} = video;

  const duration = endSecond - startSecond;
  const durationString = toHHMMSS(duration);

  const videoPageURL = `/creator/my-video/${id}`;

  return (
    <Styles.VideoCard>
      <Link to={videoPageURL}>
        <Styles.ThumbnailContainer>
          <Styles.VideoCardImg variant='top' src={`https://img.youtube.com/vi/${idYoutube}/hqdefault.jpg`} />
          <Styles.TimeContainer>{durationString}</Styles.TimeContainer>
        </Styles.ThumbnailContainer>
      </Link>
      <Styles.VideoCardBody>
        <Link to={videoPageURL} className='link-no-style'>
          <Styles.VideoCardTitle>{name}</Styles.VideoCardTitle>
        </Link>
        <Styles.VideoCardText>
          <Badge>{code}</Badge> • {getPassedTimeInString(createdAt)}
        </Styles.VideoCardText>
      </Styles.VideoCardBody>
    </Styles.VideoCard>
  );
};

export default VideoCard;
