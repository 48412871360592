import {SerializedError} from '@reduxjs/toolkit';
import {FetchBaseQueryError} from '@reduxjs/toolkit/query';

export const ErrorDictionary: Record<string, string> = {
  ACCOUNT_DISCONNECTED: 'Your account has been disconnected',
  ANNOTATION_NOT_FOUND: 'The requested annotation does not exist',
  ANSWER_ERROR: 'Cannot process the answer',
  ASSISTANT_NOT_FOUND: 'ASSISTANT_NOT_FOUND',
  AUTH_TOKEN_EXPIRED: 'Your session has expired. Please log in again.',
  AUTHENTICATION_FAILED: 'Authentication failed. Please log in again.',
  EMAIL_ALREADY_REGISTERED: 'This email is already registered',
  GENERIC_ERROR: "I'm sorry, something went wrong. Please try again later.",
  INVALID_CREDENTIALS: 'Invalid credentials. Your username/email or password is incorrect.',
  INVALID_REFRESH_TOKEN: 'Your session has expired. Please log in again.',
  NO_AUTH_TOKEN: 'You are not logged in. Please log in to continue.',
  QUESTION_GENERATION_ABORTED: 'QUESTION_GENERATION_ABORTED',
  QUESTION_GENERATION_EXPIRED: 'QUESTION_GENERATION_EXPIRED',
  QUESTION_GENERATION_FAILED: 'QUESTION_GENERATION_FAILED',
  QUESTION_NOT_FOUND: 'The requested question does not exist',
  QUIZ_NOT_FOUND: 'QUIZ_NOT_FOUND',
  SECONDS_ERROR: 'The end second must be greater than the start second',
  TRANSCRIPTION_NOT_FOUND: 'The requested transcription does not exist',
  THREAD_NOT_FOUND: 'The requested thread does not exist',
  TOPIC_GENERATION_FAILED: "Sorry, we couldn't generate topics for this video",
  TOPICS_NOT_FOUND: 'The requested topics do not exist',
  VALIDATION_FAILED: 'The parameters you provided are invalid',
  VIDEO_NOT_FOUND: 'The requested video does not exist',
  VIDEO_ALREADY_IN_LIBRARY: 'The video is already in your library',
  VIDEO_NOT_IN_LIBRARY: 'The video is not in your library',
  USERNAME_ALREADY_REGISTERED: 'This username is already registered',
  USER_NOT_CREATOR: 'You cannot perform this action as you are not a creator',
  USER_NOT_STUDENT: 'You cannot perform this action as you are not a student',
  WRONG_FUNCTION_CALL: 'WRONG_FUNCTION_CALL',
};

export const translateErrorCode = (errorCode: string) => {
  const message = ErrorDictionary[errorCode];
  if (!message) return ErrorDictionary['GENERIC_ERROR'];
  return message;
};

interface ICode {
  code: string;
}

function hasCode(obj: unknown): obj is ICode {
  return (obj as ICode)?.code !== undefined && typeof (obj as ICode).code === 'string';
}

export const getMessageFromError = (error: FetchBaseQueryError | SerializedError): string => {
  if (isFetchBaseQueryError(error)) {
    let code = 'GENERIC_ERROR';
    if (error.data) {
      const data = error.data as {code?: string};
      code = hasCode(data) ? data.code : 'GENERIC_ERROR';
    }
    return translateErrorCode(code);
  } else {
    // Here we have SerializedError
    const message = error.message || 'An error occurred';
    return message;
  }
};

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(error: unknown): error is {message: string} {
  return typeof error === 'object' && error != null && 'message' in error && typeof (error as any).message === 'string';
}
