import styled from 'styled-components';

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  display: block;
  background-color: #000;
  overflow: hidden;
  aspect-ratio: 16 / 9;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    /* Extend it beyond the viewport... */
    width: 300%;
    height: 100%;
    /* ...and bring it back again */
    margin-left: -100%;

    .ypt-title {
      display: none !important;
    }
  }
`;
