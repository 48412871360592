import {useState} from 'react';
import useFetch from './hooks/useFetch';
import {Alert, Button, Col, Container, Form, Row, Table} from 'react-bootstrap';
import {toHHMMSS} from '../../../../utils/time';
import Loading from '../../../../components/loading/Loading';
import {useDeleteTranscriptMutation, useGenerateTranscriptWithWhisperMutation, useGenerateTranscriptWithoutWhisperMutation, useJoinTranscriptMutation} from '../../../../store/videos/videos.api';
import toast from 'react-hot-toast';
import {getMessageFromError} from '../../../../utils/errors';
import {useVideo} from '../../TeacherVideo';

const Transcript = () => {
  const {video} = useVideo();
  const {transcriptSegments, isError, isLoading: isLoading1, errorMessage, isSuccess} = useFetch(video.id);
  const canGenerateTranscript = !transcriptSegments || transcriptSegments.length === 0;
  const canDeleteTranscript = !canGenerateTranscript;

  const [deleteTranscript, {isLoading: isLoading2}] = useDeleteTranscriptMutation();
  const [generateTranscriptWithoutWhisper, {isLoading: isLoading3}] = useGenerateTranscriptWithoutWhisperMutation();
  const [generateTranscriptWithWhisper, {isLoading: isLoading4}] = useGenerateTranscriptWithWhisperMutation();
  const [joinTranscriptSentences, {isLoading: isLoading5}] = useJoinTranscriptMutation();
  const isLoading = isLoading1 || isLoading2 || isLoading3 || isLoading4 || isLoading5;
  const [decimalDigits, setDecimalDigits] = useState(0);

  const onClickGenerateTranscriptWithouthWhisper = () => {
    if (!canGenerateTranscript) return;
    toast.promise(generateTranscriptWithoutWhisper(video.id).unwrap(), {
      loading: 'Generating transcript...',
      success: 'Transcript generated successfully',
      error: (err) => `Failed to generate transcript. ${getMessageFromError(err)}`,
    });
  };

  const onClickGenerateTranscriptWithWhisper = (timestamp_granularities: 'word' | 'segment') => {
    if (!canGenerateTranscript) return;
    toast.promise(generateTranscriptWithWhisper({videoId: video.id, timestamp_granularities}).unwrap(), {
      loading: 'Generating transcript...',
      success: 'Transcript generated successfully',
      error: (err) => `Failed to generate transcript with Whisper. ${getMessageFromError(err)}`,
    });
  };

  const onClickJoinSentences = () => {
    if (!canDeleteTranscript) return;
    toast.promise(joinTranscriptSentences(video.id).unwrap(), {
      loading: 'Joining sentences...',
      success: 'Sentences joined successfully',
      error: (err) => `Failed to join sentences. ${getMessageFromError(err)}`,
    });
  };

  const onClickDeleteTranscript = () => {
    if (!canDeleteTranscript) return;
    toast.promise(deleteTranscript(video.id).unwrap(), {
      loading: 'Deleting transcript...',
      success: 'Transcript deleted successfully',
      error: (err) => `Failed to delete transcript. ${getMessageFromError(err)}`,
    });
  };

  return (
    <Container className='py-4'>
      <Row>
        <Col>
          <h2>Actions</h2>
          <div className='d-flex flex-row gap-2 justify-content-center align-items-center'>
            <Button variant='success' onClick={onClickGenerateTranscriptWithouthWhisper} disabled={!canGenerateTranscript}>
              Generate transcript without Whisper
            </Button>
            <Button variant='success' onClick={() => onClickGenerateTranscriptWithWhisper('segment')} disabled={!canGenerateTranscript}>
              Generate transcript with Whisper
            </Button>
            <Button onClick={onClickJoinSentences} variant='warning' disabled={canGenerateTranscript}>
              Join sentences
            </Button>
            <Button onClick={onClickDeleteTranscript} variant='danger' disabled={!canDeleteTranscript}>
              Delete transcript
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className='my-4'>Transcript</h2>
          {isLoading ? (
            <Loading page={false} />
          ) : isError ? (
            <Alert variant='danger'>{errorMessage}</Alert>
          ) : !transcriptSegments.length ? (
            <p className='text-muted fst-italic'>No transcript found</p>
          ) : (
            isSuccess && (
              <>
                <Form.Switch id='decimalDigits' label='Show decimal digits' checked={decimalDigits > 0} onChange={(e) => setDecimalDigits(e.target.checked ? 4 : 0)} />
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Start</th>
                      <th>End</th>
                      <th>Text</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transcriptSegments.map((segment, index) => (
                      <tr key={segment.id}>
                        <td>{index + 1}</td>
                        <td>{toHHMMSS(segment.startSecond, decimalDigits)}</td>
                        <td>{toHHMMSS(segment.endSecond, decimalDigits)}</td>
                        <td>{segment.text}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Transcript;
